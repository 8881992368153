<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <div class="box is-flex is-flex is-justify-content-space-between is-align-items-center">
            <b-field :label="$t('CONCILIACAOBANCARIA.TRANSACAO')">
                {{conciliacao.transacao.descricao}}
            </b-field>
            <b-field :label="$t('CONCILIACAOBANCARIA.VALORTRANSACAO')">
                {{conciliacao.transacao.valor / 100 | currency}}
            </b-field>
            <b-field :label="$t('CONCILIACAOBANCARIA.TOTALSELECIONADO')">
                {{somaLancamentosSelecionados / 100 | currency}}
            </b-field>
            <div class="buttons">
                <b-button @click="confirmar" type="is-success" :loading="isLoading" icon-left="check-circle">
                    {{$t('CONCILIACAOBANCARIA.CONFIRMAR')}}
                </b-button>
            </div>
        </div>
        <div class="box">
            <b-tabs position="is-right" type="is-boxed" v-model="tabAtiva">
                <b-tab-item>
                    <template slot="header">
                        <span @click="tabAtiva == 0"> {{$t('CONCILIACAOBANCARIA.FILTRO')}} </span>
                    </template>
                    <div class="box">
                        <div class="columns">
                            <div class="column is-2">
                                <b-field :label="$t('CONCILIACAOBANCARIA.TIPOREGISTRO')">
                                    <b-select placeholder="" v-model="filtros.tipo" expanded>
                                        <option v-for="opcao in tiposRegistros" :value="opcao.id" :key="opcao.id">
                                            {{ opcao.titulo }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="column is-2">
                                <b-field :label="$t('CONCILIACAOBANCARIA.NOTAFISCAL')">
                                    <b-input type="text" v-model="filtros.notaFiscal" />
                                </b-field>
                            </div>
                            <div class="column is-4">
                                <searchIdName :id.sync="filtros.especiePagamento.id"
                                    :item.sync="filtros.especiePagamento" table="EspeciePagamento"
                                    :label="$t('CONCILIACAOBANCARIA.ESPECIEPAGAMENTO')" />
                            </div>
                            <div class="column is-4">
                                    <searchIdName :id.sync="filtros.unidade.id" :item.sync="filtros.unidade"
                                        table="Unidade" :label="$t('SISTEMA.UNIDADE')" />
                            </div>
                        </div>
                        <div v-show="filtros.tipo == 'receber'">
                            <div class="columns">
                                <div class="column is-6">
                                    <searchIdName :id.sync="filtros.convenio.id" :item.sync="filtros.convenio"
                                        table="Convenio" :label="$t('SISTEMA.CONVENIO')" />
                                </div>
                                <div class="column is-6">
                                    <searchIdName :id.sync="filtros.paciente.id" :item.sync="filtros.paciente"
                                        table="Paciente" :label="$t('SISTEMA.PACIENTE')" />
                                </div>
                            </div>
                        </div>
                        <div v-show="filtros.tipo == 'pagar'">
                            <div class="columns">
                                <div class="column is-6">
                                    <searchIdName :id.sync="filtros.categoriaFinanceira.id" :item.sync="filtros.categoriaFinanceira"
                                        table="CategoriaFinanceira" :label="$t('SISTEMA.CATEGORIAFINANCEIRA')" />
                                </div>
                                <div class="column is-6">
                                    <searchIdName :id.sync="filtros.fornecedor.id" :item.sync="filtros.fornecedor"
                                        table="Fornecedor" :label="$t('SISTEMA.FORNECEDOR')" />
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-3">
                                <div class="panel">
                                    <p class="panel-tabs">
                                        <a :class="{ 'is-active': filtros.tipoDeData.valorSelecionado == 'vencimento' }"
                                            @click="filtros.tipoDeData.valorSelecionado = 'vencimento'">Vencimento</a>
                                        <a :class="{ 'is-active': filtros.tipoDeData.valorSelecionado == 'criacao' }"
                                            @click="filtros.tipoDeData.valorSelecionado = 'criacao'">Criação</a>
                                        <a :class="{ 'is-active': filtros.tipoDeData.valorSelecionado == 'pagamento' }"
                                            @click="filtros.tipoDeData.valorSelecionado = 'pagamento'">Pagamento</a>
                                    </p>
                                    <div class="panel-block">
                                        <b-datepicker editable :placeholder="$t('MENUFINANCEIRO.PERIODO')"
                                            v-model="filtros.datas" position="is-bottom-right" range>
                                        </b-datepicker>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav class="level">
                            <div class="level-item">
                                <p class="buttons">
                                    <b-button type="is-info" :loading="isLoading" @click="filtrar" icon-left="magnify">
                                        {{$t('CONCILIACAOBANCARIA.BUSCAR')}}
                                    </b-button>
                                </p>
                            </div>
                        </nav>
                    </div>
                    <div class="box">
                        <b-table :data="tratarListaDeCandidatosParaConciliacao" striped hoverable paginated backend-pagination :total="total"
                            @page-change="mudarPagina" :aria-next-label="$t('CONCILIACAOBANCARIA.PROXPAGINA')"
                            :aria-previous-label="$t('CONCILIACAOBANCARIA.PAGANTERIOR')"
                            :aria-page-label="$t('CONCILIACAOBANCARIA.PAGINA')"
                            :aria-current-label="$t('CONCILIACAOBANCARIA.PAGATUAL')">
                            <template>
                                <b-table-column v-slot="props" field="parcelaValor"
                                    :label="$t('CONCILIACAOBANCARIA.VALOR')">
                                    {{ props.row.parcelaValor | currency }}
                                </b-table-column>
                                <b-table-column v-slot="props" field="parcelaVencimento"
                                    :label="$t('CONCILIACAOBANCARIA.DATAVENCIMENTO')">
                                    {{ props.row.parcelaVencimento | moment("DD/MM/YYYY")}}
                                </b-table-column>
                                <b-table-column v-slot="props" field="parcelaNotaFiscal"
                                    :label="$t('CONCILIACAOBANCARIA.NOTAFISCAL')">
                                    {{ props.row.notaFiscal}}
                                </b-table-column>
                                <b-table-column v-slot="props" field="referenciaLancamento"
                                    :label="$t('CONCILIACAOBANCARIA.REFERENCIA')">
                                    <router-link target='_blank' v-if="props.row.tipo == 'Pagar'"
                                        :to="{ name: 'contaspagar', params: { id: props.row.id }}">
                                        {{ props.row.descricao}}
                                    </router-link>
                                    <router-link target='_blank' v-else
                                        :to="{ name: 'contas-receber', params: { id: props.row.id}}">
                                        {{ props.row.descricao}}
                                    </router-link>
                                </b-table-column>
                                <b-table-column v-slot="props" custom-key="actions" numeric>
                                    <b-button @click="selecionarLancamento(props.row.parcelaId, props.row.tipo)" type="is-success" title="Clique para selecionar" icon-left="plus">
                                    </b-button>
                                </b-table-column>
                            </template>
                        </b-table>
                    </div>
                </b-tab-item>
                <b-tab-item>
                    <template slot="header">
                        <span @click="tabAtiva == 0"> {{$t('CONCILIACAOBANCARIA.SELECIONADO')}} </span>
                    </template>
                    <div>
                        <listaLancamentosFinanceiros :lancamentos-financeiros="conciliacao.lancamentosFinanceiros"
                            @removeuLancamento="desselecionarLancamento"
                            :podeRemoverItem="true">
                        </listaLancamentosFinanceiros>
                    </div>
                </b-tab-item>
            </b-tabs>
        </div>
    </section>
</template>

<style scoped>

</style>
<script>
import listaLancamentosFinanceiros from "@/components/conciliacaoBancaria/listaLancamentosFinanceiros.vue"
import searchIdName from '@/components/searchIdName.vue'
import adaptador from "../../assets/js/views/conciliacaoBancaria/adaptadorDadoLancamentoFinanceiro.js"

export default {
    data() {
        return {
            isLoading: false,
            tabAtiva: 0,
            model: [],
            page: 1,
            perPage: 20,
            total: 0,
            filtros: {
                tipo: 'receber',
                tipoDeData: {
                    valorSelecionado: 'vencimento'
                },
                datas: null,
                especiePagamento: {
                    id: null,
                    nome: null
                },
                convenio: {
                    id: null,
                    nome: null
                },
                paciente: {
                    id: null,
                    nome: null
                },
                unidade: {
                    id: null,
                    nome: null
                },
                categoriaFinanceira: {
                    id: null,
                    nome: null
                },
                fornecedor: {
                    id: null,
                    nome: null
                },
                notaFiscal: null
            },
            tiposRegistros: [
                {
                    id: 'receber',
                    titulo: 'Conta a receber'
                },
                {
                    id: 'pagar',
                    titulo: 'Conta a pagar'
                }
            ]
        };
    },
    components: {
        listaLancamentosFinanceiros,
        searchIdName
    },
    props: {
        conciliacao: {},
        lancamentosSelecionados: {
            receber: [],
            pagar: []
        }
    },
    computed: {
        somaLancamentosSelecionados() {
            return this.conciliacao.lancamentosFinanceiros
                .reduce((valorAnterior, lancamentoFinanceiro) => {
                    let valorLancamentoFinanceiro = 0
                    if (lancamentoFinanceiro.parcelaReceber != null) {
                        valorLancamentoFinanceiro = lancamentoFinanceiro.parcelaReceber.valor * 100
                    }

                    if (lancamentoFinanceiro.parcelaPagar != null) {
                        valorLancamentoFinanceiro = (-lancamentoFinanceiro.parcelaPagar.valor) * 100
                    }
                    return valorAnterior + valorLancamentoFinanceiro
                }, 0)
        },
        tratarListaDeCandidatosParaConciliacao()
        {
            const lancamentosFinanceiros = this.retirarCandidatosQueJaForamSelecionados(this.model)
            return adaptador.adaptarDadosLancamentosFinanceiros()(lancamentosFinanceiros)
        },
        retirarCandidatosQueJaForamSelecionados()
        {
            return (lancamentos) => lancamentos.filter(lancamento => {
                if (lancamento.parcelaReceber != null)
                    return !this.lancamentosSelecionados.receber.includes(lancamento.parcelaReceber.id)

                if (lancamento.parcelaPagar != null)
                    return !this.lancamentosSelecionados.pagar.includes(lancamento.parcelaPagar.id)
            })
        }
    },
    methods: {
        confirmar() {
            this.$emit('confirmou')
        },
        buscarContasAReceber() {
            let params = [
                `pagina=${this.page}`,
                `contaCorrenteId=${this.conciliacao.transacao.contaCorrente.id}`,
            ]

            if (this.filtros.notaFiscal) {
                params.push(`notaFiscalId=${this.filtros.notaFiscal}`)
            }

            if (this.filtros.especiePagamento.id) {
                params.push(`especieId=${this.filtros.especiePagamento.id}`)
            }

            if (this.filtros.convenio.id) {
                params.push(`convenioId=${this.filtros.convenio.id}`)
            }

            if (this.filtros.paciente.id) {
                params.push(`pacienteId=${this.filtros.paciente.id}`)
            }

            if (this.filtros.unidade.id) {
                params.push(`unidadeId=${this.filtros.unidade.id}`)
            }

            if (this.filtros.tipoDeData.valorSelecionado) {
                params.push(`filtrarDataPor=${this.filtros.tipoDeData.valorSelecionado}`)
            }

            if (this.filtros.datas) {
                params.push(`dataInicial=${this.$moment(this.filtros.datas[0]).format('YYYY-MM-DD')}`)
                params.push(`dataFinal=${this.$moment(this.filtros.datas[1]).add(1, 'days').format('YYYY-MM-DD')}`)
            }

            params = params.join('&')
            this.loading = true
            this.$http.get(`/api/ConciliacaoBancaria/BuscarContasAReceberParaConciliar?${params}`)
                .then(({ data }) => {
                    this.model = data.lista.map(contaAReceber => ({ parcelaReceber: contaAReceber, parcelaPagar: null }));
                    if (data.lista.length == 0 && this.page > 1) {
                        this.page = 1
                        this.buscarContasAReceber()
                    }
                    this.total = this.perPage * data.pageCount
                    this.isLoading = false
                })
                .catch((error) => {
                    this.model = []
                    this.total = 0
                    this.isLoading = false
                    throw error
                })
        },
        buscarContasAPagar() {
            let params = [
                `pagina=${this.page}`,
                `contaCorrenteId=${this.conciliacao.transacao.contaCorrente.id}`,
            ]

            if (this.filtros.notaFiscal) {
                params.push(`notaFiscalId=${this.filtros.notaFiscal}`)
            }

            if (this.filtros.especiePagamento.id) {
                params.push(`especieId=${this.filtros.especiePagamento.id}`)
            }

            if (this.filtros.unidade.id) {
                params.push(`unidadeId=${this.filtros.unidade.id}`)
            }

            if (this.filtros.categoriaFinanceira.id) {
                params.push(`categoriaFinanceiraId=${this.filtros.categoriaFinanceira.id}`)
            }

            if (this.filtros.fornecedor.id) {
                params.push(`fornecedorId=${this.filtros.fornecedor.id}`)
            }

            if (this.filtros.tipoDeData.valorSelecionado) {
                params.push(`filtrarDataPor=${this.filtros.tipoDeData.valorSelecionado}`)
            }

            if (this.filtros.datas) {
                params.push(`dataInicial=${this.$moment(this.filtros.datas[0]).format('YYYY-MM-DD')}`)
                params.push(`dataFinal=${this.$moment(this.filtros.datas[1]).add(1, 'days').format('YYYY-MM-DD')}`)
            }

            params = params.join('&')
            this.loading = true
            this.$http.get(`/api/ConciliacaoBancaria/BuscarContasAPagarParaConciliar?${params}`)
                .then(({ data }) => {
                    this.model = data.lista.map(contaAPagar => ({ parcelaReceber: null, parcelaPagar: contaAPagar }));
                    if (data.lista.length == 0 && this.page > 1) {
                        this.page = 1
                        this.buscarContasAPagar()
                    }
                    this.total = this.perPage * data.pageCount
                    this.isLoading = false
                })
                .catch((error) => {
                    this.model = []
                    this.total = 0
                    this.isLoading = false
                    throw error
                })

        },
        filtrar() {
            switch (this.filtros.tipo) {
                case 'receber':
                    this.buscarContasAReceber()
                    break
                case 'pagar':
                    this.buscarContasAPagar()
                    break
                default:
                    break
            }
        },
        selecionarLancamento(parcelaId, tipoParcela)
        {
            let lancamentoFinanceiro;
            if (tipoParcela == 'Receber')
                lancamentoFinanceiro = this.model.find(lancamentoFinanceiro => lancamentoFinanceiro.parcelaReceber.id == parcelaId)
            if (tipoParcela == 'Pagar')
                lancamentoFinanceiro = this.model.find(lancamentoFinanceiro => lancamentoFinanceiro.parcelaPagar.id == parcelaId)

            this.$emit('selecionouLancamentoFinanceiro', lancamentoFinanceiro)
        },
        desselecionarLancamento(parcelaId, tipoParcela)
        {
            this.$emit('desselecionouLancamentoFinanceiro', parcelaId, tipoParcela)
        },
        mudarPagina(page) {
            this.page = page
            this.filtrar()
        }
    }
}

</script>