
<template>
    <div class="column is-12" >
        <div v-for="(evento,index) in eventos" :key="index"
            :class="tipoEventoStyle(evento)"  >
            <div class="column is-2 has-text-centered">
                <b class="is-size-6 is-paddingless" >{{evento.hour}}</b>
                <b-icon v-if="evento.tipoAgendamentoCor"
                        :style="tipoAgendamentoStyle(evento)"    
                        size="is-small"                                            
                        icon="square-rounded"></b-icon>
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && !isConfirmado(evento)" :title="$t('AGENDA.PACIENTENAOCONFIRMADO')" class="has-text-danger">!</b> 
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && isConfirmado(evento)" :title="$t('AGENDA.PACIENTECONFIRMADO')" class="has-text-success">&radic;</b> 
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && isEncaixe(evento)" :title="$t('AGENDA.PACIENTEENCAIXE')" class="has-text-black">&crarr;</b> 
                
                                       
            </div>
            <div class="column is-10" style="margin-left: 5px; min-height: 40px;">

                <span  v-show="habilitarBotoes(evento)"  class="is-pulled-right">

                    <b-button class="is-pulled-right" position="is-bottom-left"  v-show="!isBloqueio(evento) && !isAgendado(evento) && !isReservado(evento)"   :title="$t('AGENDA.BLOQUEARHORARIO')" 
                        icon-left="cancel" type="is-black"   @click="bloquearHorario(evento)" ></b-button>                                                          
                    <b-button class="is-pulled-right" position="is-bottom-left" v-show="isBloqueio(evento) || isReservado(evento)  "  
                        :title="$t('AGENDA.DESBLOQUEARHORARIO')" icon-left="lock-open"  @click="desbloquearHorario(evento.id) "   ></b-button> 
                    <b-button  class="is-pulled-right" position="is-bottom-left" v-show="!isBloqueio(evento) && !isAgendado(evento) && !isReservado(evento) " :title="$t('AGENDA.INCLUIRPACIENTE')"  
                        icon-left="account-plus" @click="incluirNovoHorarioDia(evento, evento.dataHora)"></b-button>                                

                         
                </span>
                             
                <p  class="is-size-5" >{{tratarNomePaciente(evento)}}</p>
                              
            </div>
        </div>
    </div>
</template>


<script>

import moment from 'moment';
import modalBloquearHorarios from '@/components/atendimento/modalBloquearHorarios.vue';
import { mapGetters } from 'vuex';

export default {
    props: {
        eventos: Array,
        funcionarioId: Number,   
        itemId: Number,   
        agendamentoId: Number,     
        agendaWorklist: {
            type: Boolean,
            default: false
        },
        usuario: Object             
    },
    computed: {
        ...mapGetters([
            'isInRole'
        ]),    
    },
    methods: {

        habilitarBotoes(evento){
            return this.$moment(evento.dataHora).toDate() > new Date();                                
        },        

        tipoAgendamentoStyle(evento) {
            return 'color: #' + evento.tipoAgendamentoCor + '!important'
        },
        tipoEventoStyle(evento) {
            let classes = 'box columns is-mobile is-gapless is-paddingless';
            
            if(this.isBloqueio(evento))
                classes += ' has-text-light has-background-dark';

            if(this.isAgendado(evento))
                classes += ' has-background-info has-text-light';

            if(this.isReservado(evento))
                classes += ' has-background-dark has-text-danger-light';                                    
                
            return classes;                

        },    
  
        tratarNomePaciente(evento){

            if(evento.pacienteNome){
                return evento.pacienteNome.toLowerCase();
            }else if(evento.observacao == 'Reservado' || evento.observacao == 'RESERVADO_TEMPORARIAMENTE') {
                // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
                return this.$t('AGENDA.HORARIORESERVADO');
            }else if(evento.id>0 && !evento.pacienteNome){
                return evento.motivoBloqueio != null ? `${this.$t('AGENDA.HORARIOBLOQUEADO')} (${evento.motivoBloqueio})` : this.$t('AGENDA.HORARIOBLOQUEADO');
            }
            
        },

        isReservado(evento){              
            // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
            return (evento.id && !evento.pacienteNome && (evento.observacao=='Reservado' || evento.observacao=='RESERVADO_TEMPORARIAMENTE'));                
        }, 
        isBloqueio(evento){              
            // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
            return (evento.id && (!evento.pacienteNome || !evento.observacao == 'Reservado' || !evento.observacao == 'RESERVADO_TEMPORARIAMENTE') && evento.observacao=='Bloqueio');                
        }, 
        isAgendado(evento){              
            
            return (evento.pacienteNome);                
        },  
        isConfirmado(evento){              
            
            return (evento.pacienteConfirmado);                
        },  
        isEncaixe(evento){              
            
            return (evento.encaixe);                
        },                          
        desbloquearHorario(id){                
            const evento = {id: id};

            this.$http.get(`/api/agenda/BuscaLogUsuarioGuiaAgenda?eventoId=${id}`)
                .then(res => {
                    const eventoLog = res.data.log[0]
                    if(this.usuario.id == eventoLog.usuarioId){
                        this.agendaStatus(evento, 'cancelar');
                    } else{
                        if (this.isInRole('agenda-desbloquear-horario-outro-usuario')) {
                            this.agendaStatus(evento, 'cancelar');
                        } else {
                            this.$buefy.toast.open({
                                message: this.$t('AGENDA.DESBLOQUEARHORARIOOUTROUSUARIO'),
                                type: 'is-warning',
                                duration: 5000
                            });
                        }
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        },           
                


        bloquearHorario(evento){            
            const dataHoraFim = this.$moment(evento.dataHora).add(evento.intervaloDuracaoAtendimento, 'm').toDate();
            
            this.$buefy.modal.open({
                parent: this,
                component: modalBloquearHorarios,
                events: {
                    loadDataDia: this.loadDataDias
                },  
                props: {
                    dataHoraFim: dataHoraFim,
                    evento: evento,
                },                                    
                hasModalCard: true,
                trapFocus: true
            });    
        },          
         incluirNovoHorarioDia(evento,dataHora){   
                        

            if(evento.id > 0){

                this.$buefy.dialog.confirm({
                    title: this.$t('AGENDA.ATENCAO'),
                    message: this.$t('AGENDA.CONFIRMAPACIENTE'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {       
                        evento.funcionarioId = (this.funcionarioId) ? this.funcionarioId : null                                                                   
                        this.$emit("incluirNovoHorarioDia", evento,dataHora,false);                        
                    },
                                    
                })

            }else{
                evento.funcionarioId = (this.funcionarioId) ? this.funcionarioId : null ;
                evento.itemId = this.itemId;   
                this.$emit("incluirNovoHorarioDia", evento,dataHora,false);
            }


         },         
         loadDataDias(){
            this.$emit('loadDataDias');
         },
        async agendaStatus(evento,acao) {
            const params = [];
            if (evento.id) params.push(`id=${evento.id}`);

            params.push(`acao=${acao}`);
            if (evento.itemId) params.push(`itemId=${evento.itemId}`);

            try {
                const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                const res = await this.$http.get(url);
                this.$emit('loadDataDias')
            } catch (e) {
                console.error(e);
            }

        }                                      
    }
};
</script>