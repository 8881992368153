export default function filterValidate(filtros) {
    const paciente = filtros.paciente?.split(' ');
    const nome = paciente != null ? paciente[0] : null;
    const sobrenome = paciente != null ? paciente[1] : null;
    const dataDeNascimento =  paciente?.some(item => item.includes('/'));

    const temGuia = /^[0-9]+$/.test(filtros.paciente);
    const semNomeESobrenome = nome == null || sobrenome == null;
    const semReferencia = filtros.referencia == null || filtros.referencia == "";

    return (dataDeNascimento || (semNomeESobrenome && !temGuia)) && semReferencia;
}