<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('MODALSMS.ENVIARMENSAGEM')}}</p>
        </header>
        <section class="modal-card-body">
            <div class="columns">
                <div class="column">
                    <b-field :label="$t('MODALSMS.NUMERO')">
                        <b-input readonly
                                 expanded
                                 v-model="numero"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field :label="$t('MODALSMS.MENSAGEM')">
                        <b-input type="textarea"
                                 expanded
                                 maxlength="150"
                                 v-model="mensagem"></b-input>
                    </b-field>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success" @click="enviarMensagem">{{$t('SISTEMA.ENVIAR')}}</b-button>
            <button class="button" type="button" @click="$emit('close')">Fechar</button>
        </footer>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            id: Number,
            numero: String
        },
        data() {
            return {
                mensagem: null
            }
        },
        computed: {
            ...mapState([
                'config'
            ]),
        },
        methods: {

            removerAcentos(str) {
                return str.normalize("NFD").replace(/[^a-zA-Z\s]/g, "");
            },            
            enviarMensagem() {
                if (!this.mensagem) {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: "É preciso especificar a mensagem a ser enviada!",
                        type: 'is-danger',
                        queue: false
                    })
                } else {
        
                    const params = [];                                                                                 
                    params.push('mensagem='+this.removerAcentos(this.mensagem));
                    params.push('id='+this.id);
                    params.push('numero='+this.numero);

                    try {
                        const url = `/api/manutencao/EnviarMensagemPaciente?${params.join('&')}`;
                        const res =  this.$http.get(url)
                                    .then(res => res.json())
                                    .then(data => {
                                        if (data.Ok) {                                  
                                            this.$buefy.toast.open({
                                                duration: 10000,
                                                message: "Mensagem enviada com sucesso!",
                                                type: 'is-success',
                                                queue: false
                                            });
                                            
                                        } else {
                                            this.$buefy.toast.open({
                                                duration: 10000,
                                                message: data.Message,
                                                type: 'is-danger',
                                                queue: false
                                            })
                                        }
                                    });

                        this.$emit('close')
                                                
                    } catch (e) {
                        console.error(e);
                    }

                }
            }
        }
    }
</script>