<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p v-if="removerAmostraSoroteca" class="modal-card-title">{{$t('SOROTECA.REMOVERAMOSTRA')}}</p>
            <p v-else class="modal-card-title">{{$t('INCIDENCIAAMOSTRA.REGISTRAR')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification v-if="removerAmostraSoroteca" type="is-warning">
                <h5 class="is-size-5">{{$t('SOROTECA.ATENCAO')}}</h5>
                <p>{{$t('SOROTECA.REMOVERAMOSTRAAVISO')}}</p>
            </b-notification>
            <b-notification v-if="erroIncidenciaAmostra"
                            type="is-danger">
                {{ erroIncidenciaAmostra }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field class="label">{{ titulo }}</b-field>
                </p>
                <p class="panel-block" v-if="salvarGuia" >
                    <b-tag type="is-info" v-for="(exame,index) in selecionada" :key="index">{{ exame.exameApelido }}</b-tag>
                </p>
                <p class="panel-block" v-else>
                    <b-tag type="is-info" v-for="(amostra,index) in selecionada" :key="index">{{ amostra.codigoDeBarras || amostra.amostraCodigoDeBarras }}</b-tag>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('INCIDENCIAAMOSTRA.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosIncidenciaAmostra"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosIncidenciaAmostra = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="10"
                              expanded
                              v-model="motivoIncidenciaAmostraSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosIncidenciaAmostra" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                    :loading="salvandoDados"
                    @click="salvarGuia ? enviaSalvarGuia() : confirmarIncidenciaAmostra()">
                    {{$t('SISTEMA.CONFIRMAR')}}
            </b-button>
            <b-button @click="$emit('close')">{{$t('INCIDENCIAAMOSTRA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            selecionada: Array,
            titulo: String,
            salvarGuia: Boolean,
            removerAmostraSoroteca: Boolean,
        },
        data() {
            return {
                procurarMotivosIncidenciaAmostra: '',
                listaMotivosIncidenciaAmostra: [],
                motivoIncidenciaAmostraSelecionado: [],
                erroIncidenciaAmostra: null,
                salvandoDados: false
            }
        },
        computed: {
            filteredMotivosIncidenciaAmostra() {
                return this.procurarMotivosIncidenciaAmostra
                    ? this.listaMotivosIncidenciaAmostra
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosIncidenciaAmostra.toUpperCase()) > -1)
                    : this.listaMotivosIncidenciaAmostra
            },
        },
        mounted() {
            this.loadListaMotivosIncidenciaAmostra()
        },
        methods: {
            loadListaMotivosIncidenciaAmostra() {
                if(this.removerAmostraSoroteca){
                    this.$http.get('/api/search/MotivosRemocaoAmostraSoroteca')
                    .then(m => {
                        this.listaMotivosIncidenciaAmostra = m.data
                    })
                }
                else{
                    this.$http.get('/api/search/MotivosIncidenciaAmostra')
                    .then(m => {
                        this.listaMotivosIncidenciaAmostra = m.data
                    })
                }
            },
            confirmarIncidenciaAmostra() {
                this.salvandoDados = true

                 if(this.motivoIncidenciaAmostraSelecionado == null || this.motivoIncidenciaAmostraSelecionado.length == 0) {
                    this.salvandoDados = false
                    return this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('TOAST.MOTIVOCANCELAMENTO'),
                        type: 'is-warning'
                    })
                }

                if(this.removerAmostraSoroteca){
                    const motivoIds = this.motivoIncidenciaAmostraSelecionado

                    this.$http.post(`/api/analitico/sorotecaRemover`, {amostras: this.selecionada, motivoIds: motivoIds })
                    .then((response) => {
                        if (response.status === 200) {
                            this.$router.push('/analise');
                            this.salvandoDados = false
                            this.$emit('close')
                        }
                    })
                }
                else {
                    this.$http.post('/api/atendimento/RegistraIncidenciaAmostra', {
                        guiaId: this.guia.id,
                        id: this.selecionada.map(x => x.id),
                        motivoIds: this.motivoIncidenciaAmostraSelecionado
                    })
                    .then((res) => {
                        this.salvandoDados = false
                        if (res.body.erro) {
                            this.erroIncidenciaAmostra = res.body.erro
                        } else {
                            this.$emit('loadGuia')
                            this.$emit('close')
                        }
                    })
                }
            },
            enviaSalvarGuia(){
                this.salvandoDados = true

                if(this.motivoIncidenciaAmostraSelecionado.length == 0) {
                    this.salvandoDados = false;

                    return this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('TOAST.MOTIVOCANCELAMENTO'),
                        type: 'is-warning'
                    })
                }
                
                this.guia.exames = this.selecionada.map(exame => {
                    exame.MotivoIds = this.motivoIncidenciaAmostraSelecionado;
                    return exame;
                });   
                
                this.$emit('salvarGuia')
                this.$emit('close')
            }

        }
    }
</script>