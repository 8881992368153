<template>
    <section>
        <div class="columns">
            <div class="column is-one-third">
                <div v-if="isInRole('guia-alterar') || isInRole('guia-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('ATENDIMENTO.CONSULTARGUIA')}}
                    </p>
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': guiaProcurarCampo == 'Código'}" @click="guiaProcurarPor('Código')">{{$t('ATENDIMENTO.CODIGO')}}</a>
                        <a :class="{ 'is-active': guiaProcurarCampo == 'Referência'}" @click="guiaProcurarPor('Referência')">{{$t('ATENDIMENTO.REFERENCIA')}}</a>
                        <a :class="{ 'is-active': guiaProcurarCampo == 'Amostra'}" @click="guiaProcurarPor('Amostra')">{{$t('ATENDIMENTO.AMOSTRA')}}</a>
                    </p>
                    <form @submit.prevent="abrirGuia" method="get" class="panel-block">
                        <b-input :placeholder="guiaProcurarPlaceholder"
                                 v-model="guiaProcurar"
                                 icon="magnify" ref="guiaProcurar"></b-input>
                        <input type="hidden" v-model="unidadeId" name="unidadeId" />
                    </form>
                    <div class="panel-block">
                        <router-link :to="{ name: 'guia-list', query: { unidadeId: unidadeId } }" class="button is-link is-outlined is-fullwidth is-small">
                            {{$t('ATENDIMENTO.LISTARGUIAS')}}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('guia-incluir')" class="panel">
                    <p v-if="!agendamentoDashboard" class="panel-heading background-2">
                        {{$t('ATENDIMENTO.INCLUIRGUIA')}}
                    </p>
                    <p v-if="agendamentoDashboard" class="panel-heading background-2">
                        {{$t('ATENDIMENTO.AGENDAMENTO')}}
                    </p>
                    <p class="panel-block is-justified-between">
                        {{$t('ATENDIMENTO.NOMEPACIENTE')}}
                        <b-switch v-model="agendamentoDashboard">
                            {{$t('ATENDIMENTO.AGENDAMENTO')}}
                        </b-switch>
                    </p>
                    <form @submit.prevent="procurarPaciente" method="get" class="panel-block">
                        <b-autocomplete field="nome"
                                        v-model="paciente"
                                        :placeholder="$t('ATENDIMENTO.PROCURARPACIENTE')"
                                        :data="listaPacienteAutocomplete"
                                        :loading="isFetching"
                                        @keydown.native.enter.prevent="procurarPaciente()"
                                        @typing="procurarPacienteAutocomplete"
                                        @select="option => {selected = option; this.procurarPaciente()}">
                            <template slot-scope="props">
                                <div class="media">
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                        <br>
                                        <small>
                                            <b>{{ props.option.dataDeNascimentoIdade }}</b>
                                            <span v-if="props.option.inscricao">{{$t('ATENDIMENTO.INSCRICAO')}}<b>{{ props.option.inscricao }}</b></span>
                                        </small>
                                    </div>
                                    <div class="media-right">
                                        <div v-if="props.option.pacienteEspecie && props.option.pacienteRaca">
                                            <b>{{$t('ATENDIMENTO.ESPECIE')}}: </b> {{ props.option.pacienteEspecie }} 
                                            <b>{{$t('ATENDIMENTO.RACA')}}: </b> {{ props.option.pacienteRaca }} 
                                        </div>
                                        <div  v-if="props.option.nomeResponsavel">
                                            <b>{{$t('ATENDIMENTO.RESPONSAVEL')}}</b> {{ props.option.nomeResponsavel }} 
                                        </div>                                    
                                    </div>
                                </div>
                            </template>
                        </b-autocomplete>
                        <b-button native-type="submit" icon-left="magnify"></b-button>
                    </form>
                    <div class="panel-block">
                        <a class="button is-link is-outlined is-fullwidth is-small" @click="abrirSenhasAtendimento">{{$t('ATENDIMENTO.SENHAS')}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('agenda-consultar')" class="panel">
                    <p class="panel-heading background-2">
                        {{$t('ATENDIMENTO.AGENDA')}}
                    </p>
                    <a @click="$router.push(`/atendimento/agenda-relacao?unidadeId=${unidadeId}`)">
                        <p v-if="agendaHoje.length > 0" class="panel-block">
                            <b-tooltip :label="$t('Clique aqui para visualizar os pacientes')" placement="bottom" position="is-bottom" type="is-dark">
                                {{$t('ATENDIMENTO.PACIENTESAGENDADOS')}}: <b-tag><strong>{{ agendaHoje.length }}</strong> </b-tag>
                            </b-tooltip>
                        </p>
                    </a>
                    <p v-if="agendaHoje.length == 0" class="panel-block">
                         {{$t('ATENDIMENTO.NENHUMPACIENTEAGENDADO')}}

                    </p>
                    
                    <div class="panel-block">
                        <router-link :to="{ name: 'agenda', query: { unidadeId: unidadeId } }" class="button is-link is-outlined is-fullwidth">
                            {{$t('ATENDIMENTO.ABRIRCALENDARIO')}}
                        </router-link>
                        <router-link :to="{ name: 'inspecao', query: { unidadeId: unidadeId } }" class="button is-link is-outlined is-fullwidth">
                            {{$t('ATENDIMENTO.INSPECAO')}}
                        </router-link>
                        <a @click="$router.push(`/atendimento/agenda-relacao?unidadeId=${unidadeId}`)" class="button is-info is-outlined" :title="$t('ATENDIMENTO.LISTARTODOSAGENDAMENTOS')">
                            <b-icon icon="format-list-bulleted"></b-icon>
                        </a>
                        <a class="button is-info is-outlined"
                           :href="'/Guia/RotaColetaDomiciliarHoje?unidadeId=' + unidadeId"
                           :title="$t('ATENDIMENTO.COLETADOMICILIAR')">
                            <b-icon icon="map"></b-icon>
                        </a>
                    </div>
                </div> 
                <div  v-if="config.dashboardAtendimentoSalaEspera" class="panel" >
                    <p class="panel-heading background-1">
                        {{$t('ATENDIMENTO.SALAESPERA')}}
                    </p>
                    <p  class="panel-heading">
                         <small>{{$t('ATENDIMENTO.SALAESPERAAGUARDANDO')}} </small>
                    </p>      
                    <a class="panel-block is-justified-between"   @click="$router.push({name: 'sala-espera', 
                    query: { unidadeId: unidadeId, tipoAgendamentoId: item.tipoAgendamentoId  } })"
                    v-for="(item, index) in salaEsperaHoje" v-bind:key="index" >
                
                            <span>{{item.tipoAgendamentoNome}}
                                <b-icon v-if="item.tipoAgendamentoCor"
                                        :style="tipoAgendamentoStyle(item)"    
                                        size="is-small"                                            
                                        icon="square-rounded"></b-icon>                                  
                            </span>    
 
                            <span class="tag is-rounded is-info">{{item.quantidade}}</span>                                                                
                        
                    </a>                                  
                    <div class="panel-block">
                        <router-link :to="{ name: 'sala-espera', query: { unidadeId: unidadeId } }" class="button is-link is-outlined is-fullwidth is-small">
                            {{$t('ATENDIMENTO.SALAESPERALISTAR')}}
                        </router-link>
                    </div>
                </div>                                       
            </div>
            <div class="column">
                <div v-if="isInRole('guia-receber-amostra')"  class="panel">
                    <p class="panel-heading background-1">
                        {{$t('ATENDIMENTO.COLETAMATERIAIS')}}
                    </p>
                    <div class="panel-block is-justified-between">
                        <a @click="$router.push({ name: 'coleta' })" class="button is-light is-small">Coleta por exame</a>
                        <router-link to="/atendimento/recebimentoamostras" class="button is-primary is-small is-pulled-right">
                            {{$t('ATENDIMENTO.RECEBIMENTOAMOSTRA')}}
                            
                        </router-link>
                    </div>
                    <div class="panel-block" v-if="config.naoCarregarAutomaticoPendencias && aguardandoColeta.length == 0">
                        <button class="button is-light is-fullwidth" @click="carregarColetas">{{$t('ATENDIMENTO.PENDENCIAS')}}</button>
                    </div>
                    <p v-if="aguardandoColeta.length > 0" class="panel-heading">
                        <small>{{$t('ATENDIMENTO.QUANTIDADEPACIENTES')}}</small>
                    </p>
                    <p v-if="coletasCarregadas && aguardandoColeta.length == 0" class="panel-heading">
                        <small>{{$t('ATENDIMENTO.NENHUMPACIENTES')}}</small>
                    </p>
                    <div v-for="(item, index) in aguardandoColeta" v-bind:key="index">
                        <a @click="$router.push({ name: 'coleta', 
                            query: { 
                                unidadeId: unidadeId, 
                                localId: item.localId != 0 ? item.localId : null, 
                                sepse: item.sepse,
                                dorToracica: item.dorToracica,
                                avc: item.avc,
                                traumatologia: item.traumatologia,
                                prestesAReceberAltaMedica: item.prestesAReceberAltaMedica,
                                guiaPendente: item.guiaPendente,
                                necessidadesEspeciais: item.necessidadesEspeciais,
                                guiaConferida: item.guiaConferida
                            }})" class="panel-block is-justified-between">
                            <span>{{item.localNome}}</span>
                            <guiaIndicadores v-if="!item.localNome" :guia="item"></guiaIndicadores>
                            <span class="tag is-rounded is-info">{{item.quantidade}}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('ver-ultimasGuias')" class="panel">
                    <p class="panel-heading background-2">
                        {{$t('ATENDIMENTO.ULTIMASGUIAS')}}
                    </p>
                    <div class="panel-block" v-if="config.naoCarregarAutomaticoPendencias && ultimasGuias.length == 0">
                        <button class="button is-light is-fullwidth" @click="carregarUltimasGuias">{{$t('ATENDIMENTO.MOSTRARULTIMAS')}}</button>
                    </div>
                    <div v-for="item in ultimasGuias" v-bind:key="item.id">
                        <router-link :to="{ name: 'guia', params: { id: item.id }}" class="panel-block is-justified-between">
                            <span class="tag">
                                {{item.id}}
                            </span>
                            {{item.nome}}
                        </router-link>
                    </div>
                </div>
                <div class="panel">
                    <p class="panel-heading background-1">
                        {{$t('ATENDIMENTO.PROCURAEXAMES')}}
                    </p>
                    <div class="panel-block">
                        <router-link to="/atendimento/procuradeexames" class="button is-link is-outlined is-fullwidth is-small">
                            {{$t('ATENDIMENTO.PROCURAEXAMES')}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('orcamento-consultar')"  class="panel">
                    <p class="panel-heading background-2">
                       {{$t('ATENDIMENTO.ORCAMENTO')}}
                    </p>
                    <form @submit.stop.prevent="$router.push({ name: 'orcamentos', query: { procurar: procurar }})" method="get" class="panel-block">
                        <b-input :placeholder="$t('ATENDIMENTO.PROCURAR')"
                                    name="procurar" 
                                     v-model="procurar"
                                    icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'orcamentos' }" class="button is-light is-fullwidth">
                            {{$t('ATENDIMENTO.LISTARTODOS')}}
                        </router-link>
                        <router-link v-if="isInRole('orcamento-alterar')" :to="{ name: 'orcamento' }" class="button is-link is-fullwidth">
                            {{$t('ATENDIMENTO.INCLUIR')}}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="panel">
                    <p class="panel-heading background-1">
                        {{$t('ATENDIMENTO.LOTEAMOSTRA')}}
                    </p>
                    <div class="panel-block is-justified-between columns">
                        <router-link :to="{ name: 'amostralotelistar', query: { unidadeId: unidadeId } }" class="column is-4 button is-light is-fullwidth">
                            {{$t('ATENDIMENTO.LISTAR')}}
                        </router-link>
                        <router-link :to="{ name: 'amostralotecheckpoint', query: { unidadeId: unidadeId } }" class="column is-4 button is-warning is-fullwidt">
                            <b-icon icon="flag-variant"></b-icon>
                        </router-link>
                        <router-link :to="{ name: 'amostraloteenviar', query: { unidadeId: unidadeId } }" class="column is-4 button is-primary is-fullwidth">
                            {{$t('SISTEMA.ENVIAR')}}
                        </router-link>
                    </div>
                </div>                
            </div>
            <div class="column">
                <div v-if="isInRole('entrega-resultados')" class="panel">
                    <p class="panel-heading background-2">
                        {{$t('ATENDIMENTO.ENTREGARESULTADOS')}}
                    </p>
                    <div class="panel-block">
                        <router-link to="/atendimento/entregaresultados" class="button is-info is-outlined is-fullwidth">
                            {{$t('ATENDIMENTO.REGISTRAENTREGA')}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>  
        <div v-if="isInRole('guia-integradores-consultar')"  class="columns is-multiline">
            <div v-for="integrador in integradores.filter(x => x.versao == 13)" v-bind:key="integrador.id" class="column is-4">
                <div  class="panel">
                    <p class="panel-heading background-2">
                        {{integrador.nome}}
                    </p>
                    <div class="panel-block is-justified-between">
                        <periodoHora :dataInicial.sync="integradorData[0]"
                                    :dataFinal.sync="integradorData[1]" /> 
                    </div>
                    <div class="panel-block is-justified-between">
                        <b-input v-model="carteirinha"
                                name="carteirinha" 
                                icon="magnify"></b-input>
                    </div>
                    <div class="panel-block is-justified-between">
                        <b-button type="is-primary"
                                    @click="buscarCarteirinha(integrador)"
                                    :loading="salvandoIntegrador">
                            {{$t('ATENDIMENTO.REGISTRAENTREGA')}}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>                
    </section>
</template>

<style>
    .coletaDomiciliar {
        border-left: 2px solid #5b9bd1;
        margin-left: -2px;
    }
</style>

<script>
    import { mapState, mapGetters } from 'vuex'
    import debounce from 'lodash/debounce'
    import periodoHora from '@/components/periodoHora.vue'
    import guiaIndicadores from '@/components/atendimento/guiaIndicadores.vue'
    import modalFilaAtendimento from '@/components/atendimento/modalFilaAtendimento.vue'

    export default {
        data() {
            return {
                guiaProcurar: "",
                procurar: null,
                guiaProcurarCampo: localStorage.guiaProcurarCampo ?? 'Código',
                guiaProcurarPlaceholder: this.$t('ATENDIMENTO.PROCURARGUIA'),
                agendaHoje: [],
                salaEsperaHoje: [],
                aguardandoColeta: [],
                ultimasGuias: [],

                agendaCarregada: false,                
                coletasCarregadas: false,

                listaPacienteAutocomplete: [],
                paciente: null,
                selected: null,
                isFetching: false,
                integradorData: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                carteirinha: null,
                salvandoIntegrador: false,
                agendamentoDashboard: false,
            }
        },
        components: {
            guiaIndicadores,
            periodoHora
        },
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            tipoAgendamentoStyle(evento) {
                return 'color: #' + evento.tipoAgendamentoCor + '!important'
            },            
            buscarCarteirinha(integrador){
                this.salvandoIntegrador = true;
                
                const BuscaCarteirinhaIntegrador = [
                    `carteirinha=${this.carteirinha}`,
                    `integradorId=${integrador.id}`,
                    `dataInicial=${this.$moment(this.integradorData[0]).format('YYYY-MM-DDTHH:mm:ss') }`,
                    `dataFinal=${this.$moment(this.integradorData[1]).format('YYYY-MM-DDTHH:mm:ss')}`
                ]

                this.$http.get(`/integracao/BuscaCarteirinhaIntegrador?${BuscaCarteirinhaIntegrador.join('&')}`, )
                    .then(() => {
                        this.salvandoIntegrador = false;
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('ATENDIMENTO.MENSAGEMCARTERINHA'),
                            type: 'is-success',
                            queue: false
                        })
                    })
                    .catch(err => {
                        this.salvandoIntegrador = false;
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('ATENDIMENTO.ERROCARTERINHA') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            },
            guiaProcurarPor(campo) {
                this.guiaProcurarCampo = campo
                if (campo == "Código") {
                    this.guiaProcurarPlaceholder = this.$t('ATENDIMENTO.PROCURARGUIA')
                } else if (campo == "Referência") {
                    this.guiaProcurarPlaceholder = this.$t('ATENDIMENTO.PROCURARREERENCIA')
                } else if (campo == "Amostra") {
                    this.guiaProcurarPlaceholder = this.$t('ATENDIMENTO.PROCURARAMOSTRA')
                }

                localStorage.guiaProcurarCampo = campo
            },
            abrirGuia() {
                //verifica se existe a guia
                if(this.guiaProcurarCampo == "Código"){
                    this.$http.get('/api/atendimento/edit?id=' + this.guiaProcurar)
                    .then(() => {
                        this.$router.push({
                            name: 'guia', params: {
                                id: this.guiaProcurar
                            }
                        })
                    })
                    .catch(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: this.$t('ATENDIMENTO.GUIA') + this.guiaProcurar + this.$t('ATENDIMENTO.NAOENCONTRADA'),
                            type: 'is-danger',
                            position: 'is-top-left',
                            queue: false
                        })
                    })
                }
                else if (this.guiaProcurarCampo == "Referência")
                {
                    this.$http.get('/api/atendimento/AbrirGuiaReferencia?referencia=' + this.guiaProcurar)
                    .then(res => {
                        if(res.data != null && res.data.length == 1){
                            this.$router.push({
                                name: 'referencia', params: {
                                    referencia: this.guiaProcurar
                                }
                            })
                        } else if (res.data != null && res.data.length > 1){
                            this.$router.push({
                                name: 'guia-list', params: {
                                    referencia: this.guiaProcurar
                                }
                            })
                        }
                    })
                    .catch(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: this.$t('ATENDIMENTO.GUIAREFERENCIA') + this.guiaProcurar + this.$t('ATENDIMENTO.NAOENCONTRADA'),
                            type: 'is-danger',
                            position: 'is-top-left',
                            queue: false
                        })
                    })
                }                
                else
                {
                    this.$http.get('/api/atendimento/AbrirGuia?codigoDeBarras=' + this.guiaProcurar)
                    .then(res => {
                        if(res.data != null && res.data.length == 1){
                            this.$router.push({
                                name: 'amostra', params: {
                                    amostra: this.guiaProcurar
                                }
                            })
                        } else if (res.data != null && res.data.length > 1){
                            this.$router.push({
                                name: 'guia-list', params: {
                                    amostra: this.guiaProcurar
                                }
                            })
                        }
                    })
                    .catch(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: this.$t('ATENDIMENTO.GUIAAMOSTRA') + this.guiaProcurar + this.$t('ATENDIMENTO.NAOENCONTRADA'),
                            type: 'is-danger',
                            position: 'is-top-left',
                            queue: false
                        })
                    })
                }
                
            },
            procurarPaciente() {
                if(this.selected && this.selected.id){
                    const params = { id: this.selected.id, criarGuia: true,  agendamentoDashboard: this.agendamentoDashboard };
                    this.$router.push({ name: 'paciente', params })
                }
                else {
                    const params = { procurar: this.paciente, criarGuia: true,  agendamentoDashboard: this.agendamentoDashboard };
                    this.$router.push({ name: 'pacientes', params })
                }
            },
            procurarPacienteAutocomplete: debounce(function (name) {
                if (!name.length) {
                    this.listaPacienteAutocomplete = []
                    return
                }
                this.isFetching = true

                const params = [
                    `procurar=${name}`,
                    `page=1`                                      
                ].join('&')

                this.$http.get(`/api/manutencao/pacientes?${params}`)
                    .then(({ data }) => {
                        this.listaPacienteAutocomplete = data.lista
                    })
                    .catch((error) => {
                        this.listaPacienteAutocomplete = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),
            carregarAgenda() {
                this.agendaCarregada = true;
                this.$http.get('api/Dashboard/AgendaHoje?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.agendaHoje = res.data
                    });
            },
            carregarSalaEsperaHoje() {                
                this.$http.get('api/Dashboard/SalaEspera?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.salaEsperaHoje = res.data
                    });
            },            
            carregarColetas() {
                this.coletasCarregadas = true;
                this.$http.get('api/Dashboard/AguardandoColeta?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.aguardandoColeta = res.data
                    });
            },
            carregarUltimasGuias() {
                this.$http.get('api/Dashboard/UltimasGuias?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.ultimasGuias = res.data
                    });
            },
            abrirSenhasAtendimento() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalFilaAtendimento,
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            criarGuiaAgendamento(item){
                if(item.guiaId){
                    this.$router.push({ name: 'guia-list', query: { pacienteId: item.pacienteId } })
                }
                else {
                    this.$router.push({
                        name: 'novaguia', params: {
                            agendamento: item.id,
                            pacienteId: item.pacienteId,
                        }
                    });
                }
            }
        },
        mounted() {
            this.carregarAgenda();
            if (!this.config.naoCarregarAutomaticoPendencias) {
                this.carregarAgenda();                
                this.carregarColetas();
                this.carregarUltimasGuias();
            }
            this.carregarSalaEsperaHoje();
            this.$refs.guiaProcurar.focus();
        }
    }
</script>