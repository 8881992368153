<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field label="Nome"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="40" :has-counter="false"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <label class="label">{{$t('FUNCIONARIOCADASTRO.USUARIO')}}</label>
                                     <b-field position="" expanded class="fullwidth">
                                        <b-input class="fullwidth" type="text" ref="usuario" v-model="model.usuario" maxlength="40" :has-counter="false" :readonly="travado">
                                        </b-input>
                                        <p class="control">
                                            <button class="button is-primary" icon-left="locker" @click.prevent.stop="alterarUsuario()">{{$t('FUNCIONARIOCADASTRO.ALTERAR')}}</button>
                                        </p>
                                    </b-field>
                                                                    
                                </div>                                                                
                            </div>
                            <div class="columns">
                                <div class="column">
                                     <ValidationProvider rules="required" name="senha" v-slot="{ errors, valid }" v-if="!model.id && carregado">
                                        <b-field :label="$t('FUNCIONARIOCADASTRO.SENHA')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="password" v-model="model.senha" maxlength="40" :has-counter="false"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <ValidationProvider rules="required" name="Confirmação de Senha" v-slot="{ errors, valid }" v-if="!model.id && carregado">
                                        <b-field :label="$t('FUNCIONARIOCADASTRO.CONFIRMARSENHA')"
                                                 :type="{ 'is-danger': getErrosSenha(errors[0]), 'is-success': valid }"
                                                 :message="getMensagemSenha(errors)">
                                            <b-input type="password" v-model="model.novaSenha" maxlength="40" :has-counter="false" @input="validarSenha()"></b-input>
                                        </b-field>
                                    </ValidationProvider>                                       
                                </div>
                            </div>
                          
                            <div class="columns is-multiline is-mobile">
                                <!-- {{model}} -->
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('FUNCIONARIOCADASTRO.CPF')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.cpf" v-mask="'###.###.###-##'">
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('FUNCIONARIOCADASTRO.CONSELHO')}}</label>
                                        <div class="select full-width">
                                            <select v-model="model.conselho" class="full-width">
                                                <option v-for="conselho in $store.state.conselhos"
                                                        :key="conselho.value"
                                                        :value="conselho.value">
                                                    {{ conselho.text }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>                                    
                                </div>

                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('FUNCIONARIOCADASTRO.UF')}}</label>
                                         <div class="select full-width">
                                            <select v-model="model.conselhoUF" class="full-width">
                                                <option v-for="uf in $store.state.estados"
                                                        :key="uf"
                                                        :value="uf">
                                                    {{ uf}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('FUNCIONARIOCADASTRO.NUMCONSELHO')}}</label>
                                        <div class="control">
                                            <b-input type="text" v-model="model.conselhoNumero" maxlength="15" />
                                        </div>
                                    </div>
                                </div>                                                      
                            </div>                          
                          

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('FUNCIONARIOCADASTRO.FONE')">
                                        <b-input v-model="model.fone" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('FUNCIONARIOCADASTRO.CELULAR')">
                                        <b-input v-model="model.celular" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                    </b-field>
                                </div>        
                                <div class="column is-half-mobile">
                                    <b-field :label="$t('FUNCIONARIOCADASTRO.DATA')">
                                        <b-datepicker editable v-model="model.dataDeNascimento" trap-focus></b-datepicker>
                                    </b-field>
                                </div>
                                <div class="column is-half-mobile">
                                    <div class="field">
                                        <label class="label">{{$t('FUNCIONARIOCADASTRO.IDADE')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="idade" readonly>
                                        </div>
                                    </div>
                                </div>                                                        
                            </div>

                            <div class="columns">
                                <div class="column">
                                   <div class="field">
                                        <label class="label">{{$t('FUNCIONARIOCADASTRO.CBOS')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.cbos">
                                        </div>
                                    </div>
                                </div> 
                                 <div class="column">
                                   <div class="field">
                                        <label class="label">{{$t('FUNCIONARIOCADASTRO.CODIGOOPERADORA')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.codigoNaOperadora">
                                        </div>
                                    </div>
                                </div> 
                            </div> 

                            

                            <div class="columns">
                                <div class="column">
                                    <label class="label">{{$t('FUNCIONARIOCADASTRO.NIVEL')}}</label>
                                    <b-select placeholder="Nível" v-model="model.nivel.id" expanded >
                                        <option v-for="option in model.niveis"
                                                :value="option.id"
                                                :key="option.id">
                                            {{ option.nome }}
                                        </option>
                                    </b-select>
                                </div>                                
                                <div class="column">
                                    <ValidationProvider rules="email" name="e-mail" v-slot="{ errors, valid }">
                                        <b-field :label="$t('FUNCIONARIOCADASTRO.EMAIL')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors"
                                                 >
                                            <b-input maxlength="50" type="email" v-model="model.email" ></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>                                                                    
                            </div>       

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('FUNCIONARIOCADASTRO.IDENTIFICADOR')}}</label>
                                        <div class="control">
                                            <b-input maxlength="100" type="text" v-model="model.identificador" ></b-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <searchIdName :id.sync="model.unidade.id"  :label="$t('FUNCIONARIOCADASTRO.UNIDADE')" table="Unidade"></searchIdName>                            
                                </div>

                            </div>

                            

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('FUNCIONARIOCADASTRO.ASSINATURA')}}</label>
                                        <div class="control">
                                            <textarea class="textarea" v-model="model.assinaturaTexto" maxlength="255" :has-counter="false"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('FUNCIONARIOCADASTRO.FOTO')}}
                                </div>
                                <div v-if="!capturando">
                                    <div class="panel-block">
                                        <figure class="image">
                                            <img :src="foto" ref="foto">
                                        </figure>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="panel-block">
                                        <vue-web-cam ref="webcam"
                                                     :device-id="deviceId"
                                                     width="100%"
                                                     @cameras="onCameras" />
                                    </div>
                                    <div class="panel-block">
                                        <div class="field">
                                            <div class="select">
                                                <select v-model="camera">
                                                    <option>-- Select Device --</option>
                                                    <option v-for="device in devices"
                                                            :key="device.deviceId"
                                                            :value="device.deviceId">
                                                        {{ device.label }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <b-button :class="capturando ? 'is-success' : 'is-link '" 
                                              icon-left="camera"
                                              @click="onCapture" :title="$t('FUNCIONARIOCADASTRO.CAPTURAR')"
                                              expanded>
                                    </b-button>
                                </div>
                                <div class="panel-block">
                                    <div class="file has-name fullwidth">
                                        <label class="file-label fullwidth">
                                            <input class="file-input" type="file" name="foto" ref="fileSele"  @change="imageFileSelected($event,'foto')">
                                            <span class="file-cta ">
                                                <span class="file-icon">
                                                    <i class="fas fa-upload"></i>
                                                </span>
                                                <span class="file-label">
                                                    {{$t('FUNCIONARIOCADASTRO.ARQUIVO')}}
                                                </span>
                                            </span>
                                            <span class="file-name fullwidth">Foto</span>                                            
                                        </label>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field full-width">
                                        <b-switch v-model="model.desativado" >
                                            {{$t('FUNCIONARIOCADASTRO.DESATIVADO')}}
                                        </b-switch>
                                    </div>
                                </div>
                            </article>

                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('FUNCIONARIOCADASTRO.ASS')}}
                                </div>
                                <div class="panel-block">
                                    <figure class="image" v-if="assinatura && assinatura != ''">
                                        <img :src="assinatura" ref="fotoAssinatura">
                                    </figure>
                                    <!-- <b-button v-if="nomeArquivo" icon-clickable icon-left="delete" type="is-danger" class="float-right" @click.prevent.stop="removerArquivo()"></b-button>                                                                             -->
                                    <div class="file has-name fullwidth">
                                        <label class="file-label fullwidth">
                                            <input class="file-input" type="file" name="foto" ref="fileArquivo"  @change="imageFileSelected($event,'Assinatura')">
                                        </label>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <b-button  type="is-primary" expanded @click="$refs.fileArquivo.click()">{{$t('FUNCIONARIOCADASTRO.ESCOLHERARQ')}}</b-button>                                        
                                </div>
                            </article>

                            <historico-alteracoes :tabela="'Funcionario'" :chave="model.id"></historico-alteracoes>

                            <article class="panel" v-if="model.id">
                                <div class="panel-heading has-text-centered">
                                    {{$t('FUNCIONARIOCADASTRO.MUDARSENHA')}}
                                </div>
                                <div class="panel-block">
                                    <b-field label="Nova Senha" expanded class="fullwidth">
                                            <b-input type="password" v-model="model.novaSenha" maxlength="40" :has-counter="false" expanded autocomplete="new-password"></b-input>
                                    </b-field>
                                </div>
                            </article>
                                                   
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('FUNCIONARIOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('FUNCIONARIOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style lang="scss">
.full-width {
    width: 100%;
}

.image {
    width: 100%;
}

.float-right{
    position: absolute !important;
    max-width: 1.5rem;
    max-height: 1.5rem;
    right: 1rem;
}
</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        data() {
            return {
                model: {
                    id : 0,
                    unidade:{
                        id: null,
                        nome:null,
                    },                    
                    assinatura: null,
                    assinaturaTexto: null,
                    identificador: null,
                    celular: null,
                    certificadoDigitalSenha: null,
                    conselho: null,
                    conselhoNumero: null,
                    conselhoUF: null,
                    cpf: null,
                    dataDeNascimento: null,
                    desativado: null,
                    email: null,
                    fone: null,
                    niveis: [], //{id: 1, nome: "Administrador"}, {id: 3, nome: "Administrativo"}, {id: 4, nome: "Analista"},…
                    nivel: { id: 0, nome: null} , //{id: 1, nome: "Administrador", acessaSomenteTiposDeLocais: null, administrador: false,…}
                    nome: null,
                    senha: null,
                    usuario: null,
                    novaSenha: null,
                    assinaturas: [],
                    cbos: null,
                },
                foto: null,
                assinatura: null,
                capturando: false,
                camera: null,
                deviceId: null,
                devices: [],
                carregandoHistoricoArquivos: true,
                historicoArquivos: [],
                salvandoDados: false,
                nomeSocial: false,
                criarGuia: false,
                fotoAlteradaAvatar: false,
                fotoAlteradaAssinatura: false,
                usuarioEstadoInicial: false,
                nomeArquivo: null,
                erroSenha: null ,
                travado: true,
                carregado: false
            };
        },
        components: {
            titlebar,
            historicoAlteracoes,
            searchIdName
        },
        created() {
            const id = this.$route.params?.id ? this.$route.params.id : 0;
            this.$http.get('/api/manutencao/funcionario?id=' + id)
                .then(res => {
                    this.model = res.data;
                    this.model.usuario = res.data.usuario;
                    this.model.dataDeNascimento = this.model.dataDeNascimento ? new Date(this.model.dataDeNascimento) : null;
                    this.model.criarUsuario = false;
                    this.model.novaSenha = null;
                    this.possuiUsuario = true;   
                    
                    // this.model.foto = null;
                    if (this.model.assinatura && this.model.assinatura?.length){
                        this.assinatura = `data:image/gif;base64,${this.model.assinatura}`;
                        this.nomeArquivo = 'assinatura';
                    }
                    if (this.model.id == 0 || this.model.id == null){
                        this.model.nivel= {
                            id: this.model.niveis[0]?.id
                        }
                    }

                    if(!this.model.unidade){
                        this.model.unidade = {
                            id: null,
                            nome: null
                        }

                    }
                    this.getImage();
                    this.carregado = true;
                })

        },
        computed: {
            titleStack() {
                return [
                    this.$t('FUNCIONARIOCADASTRO.MANUTENCAO'),
                    this.$t('FUNCIONARIOCADASTRO.FUNCIONARIO'),
                    this.model.id ? this.model.id :  'novo'
                ]
            },
            idade() {
                if (!this.model.dataDeNascimento) return null;
                return ~~((Date.now() - this.model.dataDeNascimento) / (31557600000));
            },
            device() {
                return this.devices.find(n => n.deviceId === this.deviceId);
            },
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
            camera: function (id) {
                this.deviceId = id;
            },
            devices: function () {
                // Once we have a list select the first one
                const [first] = this.devices;
                if (first) {
                    this.camera = first.deviceId;
                    this.deviceId = first.deviceId;
                }
            }
        },
        methods: {
            imageFileSelected(e, itemName) {               
                const {name, size} = e.target.files[0];
                let imageData = null;
                if (FileReader && size) {
                    const fr = new FileReader();
                    fr.onload = () => {
                        this['fotoAlterada'+itemName] = true;                        
                        if (itemName === 'Assinatura'){
                            itemName = 'assinatura';
                            this.nomeArquivo = name;
                        }else{
                            this.fotoAlteradaAvatar = true;
                        }
                        imageData = fr.result;
                        this[itemName] = imageData;
                        if (this.$refs[itemName]) this.$refs[itemName].src = imageData;
                    }
                    fr.readAsDataURL(e.target.files[0]);
                }
            },
            validarSenha(){
                this.erroSenha = null;
                if (this.model.id == 0){
                    if (this.model.senha != this.model.novaSenha && this.model.senha?.trim()?.length){
                        this.erroSenha = this.$t('FUNCIONARIOCADASTRO.SENHASIGUAIS');
                    }
                }
                
                return this.erroSenha == null;
            },
            async onSubmit() {          
                if (!this.validarSenha()){
                    return;
                }
                const newItem = {
                    funcionario: Object.assign({},this.model)
                }              

                //validando o usuário logado
                if (this.model.id > 0){
                    if (this.$store.state.usuario.nome === this.model.nome?.trim()){
                        this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('FUNCIONARIOCADASTRO.USUARIOLOGADO'),
                                type: 'is-danger',
                                queue: false
                            })

                        return false;
                    }
                }

                delete newItem.funcionario.foto;
                delete newItem.funcionario.niveis;
                delete newItem.funcionario.senha;
                delete newItem.funcionario.novaSenha;
                delete newItem.funcionario.usuario;

                if (this.assinatura?.length && this.fotoAlteradaAssinatura){
                    const dadosFotoArray = this.assinatura.split(',');
                    dadosFotoArray.shift();
                    newItem.assinatura = dadosFotoArray.join();
                }else{
                    if (this.assinatura != ''){
                        newItem.assinatura = null;
                    }else {
                        newItem.assinatura = '';
                    }

                }
                if (!this.$route.params?.id && this.model.usuario == null){
                    this.model.usuario = this.model.nome;
                }

                if (this.foto && this.fotoAlteradaAvatar){
                    const dadosFotoArray = this.foto.split(',');
                    dadosFotoArray.shift();
                    newItem.foto = dadosFotoArray.join();
                }else{
                    newItem.foto = null;
                }

                if (!newItem.id){
                    newItem.id = 0;
                }     
                
                if(newItem.funcionario.unidade.id == null){
                    newItem.funcionario.unidade.id = 0;
                }
                let novaSenhaString = this.model.senha ? `&novaSenha=${this.model.senha}`:'';                
                const usuarioString = this.model.usuario ? `&nomeUsuario=${this.model.usuario}` : '';
                novaSenhaString += this.model.novaSenha ? `&novaSenhaR=${this.model.novaSenha}` : '';

                this.$http.post(`/api/manutencao/funcionario?${novaSenhaString}${usuarioString}`, newItem)
                    .then((res) => {
                        if(res.data != null && res.data.ok != null && res.data.ok == false){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.data.mensagem,
                                type: 'is-warning',
                                queue: false
                            })
                        }
                        this.$router.back()
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                    })
            },
            onCapture() {
                if (this.capturando) {
                    this.foto = this.$refs.webcam.capture();
                    this.fotoAlteradaAvatar = true;
                    setTimeout(() => {
                        this.$refs.webcam.stop();
                        this.capturando = false;
                    }, 500);
                } else {
                    this.capturando = true;
                    setTimeout(() => { this.$refs.webcam.start() }, 500);
                }
            },
            onCameras(cameras) {
                this.devices = cameras;                
            },
            onCameraChange(deviceId) {
                this.deviceId = deviceId;
                this.camera = deviceId;                
            },
            getImage() {
                if (!this.foto){
                    const id = this.$route.params?.id ? this.$route.params.id : 0;
                    this.foto = '/api/manutencao/funcionarioFoto?id=' + id+`&_cl=${new Date().getTime()}`;
                }

                return this.foto;
            },
            removerArquivo(){
                this.fotoAlteradaAssinatura = true;
                this.assinaturas = [];
                this.nomeArquivo = null;
                this.assinatura = '';
            },
            getErrosSenha(errors){
                if (this.erroSenha) return true;

                return errors;
            },
            getMensagemSenha(errors){
                if (this.erroSenha){
                    if (errors?.length){
                        return [...errors,this.erroSenha];
                    }else{
                        return [this.erroSenha];
                    }

                }else{
                    return errors;
                }
            },
            alterarUsuario(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('FUNCIONARIOCADASTRO.ALTERARUSUARIO'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {
                        this.travado = false;
                        this.$refs.usuario.focus();
                    }
                })

            }
        }
    }
</script>