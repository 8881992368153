<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ $t('MODALGERARLOTEGLOSA.TITULO') }}</p>
        </header>
        <section class="modal-card-body is-centered">
             <div class="columns">
                <div class="column">
                    {{ $t('MODALGERARLOTEGLOSA.AVISO') }}
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field>
                        <b-checkbox v-model="gerarGlosarIntegrais">{{ $t('MODALGERARLOTEGLOSA.OPCAOINTEGRAL') }}</b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="gerarGlosasParciais">{{ $t('MODALGERARLOTEGLOSA.OPCAOPARCIAL') }}</b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="gerarNaoComputados">{{ $t('MODALGERARLOTEGLOSA.OPCAONAOCOMPUTADOS') }}</b-checkbox>
                    </b-field>
                </div>
                
            </div>
            <b-notification
                v-model="alertaAtivo"
                type="is-warning is-light"
                aria-close-label="Close notification"
                role="alert">
                {{ alerta }}
            </b-notification>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success" @click="gerarLote">{{ $t('MODALGERARLOTEGLOSA.GERAR') }}</b-button>
            <button class="button" type="button" @click="$emit('close')">{{ $t('MODALGERARLOTEGLOSA.FECHAR') }}</button>
        </footer>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            existeCorrespondencia: Function,
        },
        data() {
            return {
                gerarGlosarIntegrais: true,
                gerarGlosasParciais: true,
                gerarNaoComputados: true,
                alertaAtivo: false,
                alerta: ""
            }
        },
        computed: {
            ...mapState([
                'config'
            ]),
        },
        methods: {
            gerarLote()
            {
                
                const criterios = []
                if (this.gerarGlosarIntegrais) criterios.push('GLOSA_INTEGRAL');
                if (this.gerarGlosasParciais) criterios.push('GLOSA_PARCIAL');
                if (this.gerarNaoComputados) criterios.push('NAO_COMPUTADO');
                if (this.existeCorrespondencia(criterios))
                {
                    this.$emit('gerarLote', criterios)
                    this.$emit('close')
                    return;
                }
                this.alertaAtivo = true
                this.alerta = this.$t('MODALGERARLOTEGLOSA.SEMCORRESPONDENCIA')
            }
        }
    }
</script>