<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALALTERACAOAGENDA.ALTERARAGENDA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('MODALALTERACAOAGENDA.ATENCAO')}}</h5>
                <p>{{$t('MODALALTERACAOAGENDA.AVISOALTERACAO')}}</p>
            </b-notification>
            <b-notification v-if="erroCancelamentoAgenda"
                            type="is-danger">
                {{ erroCancelamentoAgenda }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALALTERACAOAGENDA.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-select expanded v-model="motivoCancelamentoAgendaSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosCancelamentoAgenda" :key="index"
                                :value="motivo.descricaoInterno">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="confirmarCancelamentoAgenda">{{$t('MODALALTERACAOAGENDA.CONFIRMARALTERACAO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('MODALALTERACAOAGENDA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                procurarMotivosCancelamentoAgenda: '',
                listaMotivosCancelamentoAgenda: [],
                motivoCancelamentoAgendaSelecionado: null,
                erroCancelamentoAgenda: null,
            }
        },
        computed: {
            filteredMotivosCancelamentoAgenda() {
                return this.procurarMotivosCancelamentoAgenda
                    ? this.listaMotivosCancelamentoAgenda.filter(x=>x.descricaoInterno!=null)
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosCancelamentoAgenda.toUpperCase()) > -1)
                    : this.listaMotivosCancelamentoAgenda
            },
        },
        mounted() {
            this.loadListaMotivosCancelamentoAgenda()
        },
        methods: {
            loadListaMotivosCancelamentoAgenda() {
                this.$http.get('/api/search/MotivosAlteracaoAgenda')
                    .then(m => {
                        this.listaMotivosCancelamentoAgenda = m.data
                    })
            },
            async confirmarCancelamentoAgenda() {
                if(this.motivoCancelamentoAgendaSelecionado == null && this.motivoCancelamentoAgendaSelecionado.length == 0) {

                    return this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('TOAST.MOTIVOCANCELAMENTO'),
                        type: 'is-warning'
                    })
                }

                if(this.motivoCancelamentoAgendaSelecionado!=null){
                    this.$emit('salvarAgendamento', this.motivoCancelamentoAgendaSelecionado)                        
                    this.$emit('close')                         
                }

            },
            

        }
    }
</script>