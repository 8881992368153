const adaptador = {
    adaptarDadosLancamentosFinanceiros()
        {
            return (lancamentos) => {
                return lancamentos.map(lancamento => {
                    const lancamentoAdaptado = {
                        id: null,
                        descricao: null,
                        parcelaId: null,
                        parcelaVencimento: null,
                        parcelaValor: null,
                        notaFiscal: null,
                        tipo: null
                    }
                    if (lancamento.parcelaPagar)
                    {
                        lancamentoAdaptado.id = lancamento.parcelaPagar.pagar.id
                        lancamentoAdaptado.descricao = lancamento.parcelaPagar.pagar.descricao
                        lancamentoAdaptado.parcelaId = lancamento.parcelaPagar.id
                        lancamentoAdaptado.parcelaValor = -lancamento.parcelaPagar.valor
                        lancamentoAdaptado.parcelaVencimento = lancamento.parcelaPagar.dataDeVencimento
                        lancamentoAdaptado.notaFiscal = lancamento.parcelaPagar.pagar.notaFiscal
                        lancamentoAdaptado.tipo = 'Pagar'
                    }
                    else
                    {
                        lancamentoAdaptado.id = lancamento.parcelaReceber.receber.id
                        if (lancamento && lancamento.parcelaReceber && lancamento.parcelaReceber.receber) {
                            const pacienteNome = lancamento.parcelaReceber.receber.paciente ? lancamento.parcelaReceber.receber.paciente.nome : '';
                            const convenioNome = lancamento.parcelaReceber.receber.convenio ? lancamento.parcelaReceber.receber.convenio.nome : '';
                            
                            if (pacienteNome && convenioNome) {
                                lancamentoAdaptado.descricao = `${pacienteNome} / ${convenioNome}`;
                            } else {
                                lancamentoAdaptado.descricao = pacienteNome || convenioNome;
                            }
                        } 
                        lancamentoAdaptado.parcelaId = lancamento.parcelaReceber.id
                        lancamentoAdaptado.parcelaValor = lancamento.parcelaReceber.valor
                        lancamentoAdaptado.parcelaVencimento = lancamento.parcelaReceber.dataDeVencimento
                        lancamentoAdaptado.notaFiscal = lancamento.parcelaReceber.receber.notaFiscal
                        lancamentoAdaptado.tipo = 'Receber'
                    }

                    return lancamentoAdaptado
                })
            }
        }
}

export default adaptador