const calcularIdade = {    
    Idade(dataDeNascimento, thisPaciente) {
        if(dataDeNascimento > new Date()){
            dataDeNascimento = null;

            this.$buefy.toast.open({
                    duration: 5000,
                    message: this.$t('PACIENTECADASTRO.NASCIMENTOINVALIDO'),
                    type: 'is-danger',
                    queue: false
                })

            return;
        }

        const today = new Date();
        let m = today.getMonth() - dataDeNascimento.getMonth();
                        
        const dateAge = new Date(Date.now() - dataDeNascimento.getTime()); 
        const age = Math.abs(dateAge.getUTCFullYear() - 1970);
                        
        if(age > 200){
            dataDeNascimento = null;

            this.$buefy.toast.open({
                    duration: 5000,
                    message: this.$t('PACIENTECADASTRO.NASCIMENTOINVALIDO'),
                    type: 'is-danger',
                    queue: false
                })

            return;
        }

        if (age >= 1) {
            if (age == 1) {
                return ~~(age) + " " + thisPaciente.$t('PACIENTECADASTRO.ANO').toLowerCase();
            } else {
                return ~~(age) + " " + thisPaciente.$t('PACIENTECADASTRO.ANOS').toLowerCase();
            }
        } else {
            if ((age == 0) && (m < 0)) {
                m += 12;
            }

            if (today.getDate() < dataDeNascimento.getDate()) {
                m--;
            }

            if (m > 0) {
                if (m == 1) {
                    return ~~(m) + " " + thisPaciente.$t('PACIENTECADASTRO.MES').toLowerCase();
                } else {
                    return ~~(m) + " " + thisPaciente.$t('PACIENTECADASTRO.MESES').toLowerCase();
                }
            } else {
                const d = (new Date(today.getFullYear(), today.getMonth(), today.getDate()) - dataDeNascimento) / 86400000;

                if (d > 0) {
                    if (d == 1) {
                        return ~~(d) + " " + thisPaciente.$t('PACIENTECADASTRO.DIA').toLowerCase();
                    } else {
                        return ~~(d) + " " + thisPaciente.$t('PACIENTECADASTRO.DIAS').toLowerCase();
                    }
                } else {
                    return "";
                }
            }
        }
    }
}

export default calcularIdade;