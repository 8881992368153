<template>
    <article v-if="chave != null" class="panel">
        <div class="panel-heading has-text-centered">
            {{$t('HISTORICO.TITULO')}}
        </div>
        <div class="panel-block">
            <b-button type.native="button" type="is-info" expanded icon-left="clock" @click="showModal" :title="$t('HISTORICO.TITULO')"></b-button>
            <b-modal :active.sync="modalHistoricoIsOpened" has-modal-card trap-focus aria-role="dialog" aria-modal>
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{$t('HISTORICO.INFORMACOES')}}</p>
                    </header>
                    <section class="modal-card-body">
                        <table class="table is-fullwidth">
                            <tr v-for="(item, index) in lista" v-bind:key="index">
                                <td v-if="item.acao == 84">
                                    <h4>{{item.descricao.replace('antes: ', '')}}</h4>
                                </td>
                                <td v-else>
                                    <h4>{{item.descricao}}</h4>
                                </td>
                                <td>
                                    <span><b>{{ item.usuarioNome }}</b> ({{ item.usuarioId }})</span>
                                </td>
                                <td>
                                    <span>{{ item.dataHora | moment('DD/MM/YYYY HH:mm') }}</span>
                                </td>
                            </tr>
                        </table>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-pulled-right" type="button" @click="TOGGLE_MODAL_HISTORICO">{{$t('HISTORICO.FECHAR')}}</button>
                    </footer>
                </div>
            </b-modal>
        </div>
    </article>
</template>

<script>
    import { mapMutations } from 'vuex'

    export default {
        props: {
            tabela: String,
            chave: Number,
            toggle:Boolean,
            agendaLog: {
                type: Boolean,
                default: false
            }  
                               
       },
        data() {
            return {
                lista: []
            }
        },
        watch: {
            modalHistoricoIsOpened(novoValor){
                if(!this.modalHistoricoIsOpened){
                    this.$emit('close')
                }
            }
        },
        created(){
            if(this.$props.toggle){
                this.showModal();
            }
        },
        computed: {
            modalHistoricoIsOpened: {
                get() {
                    return this.$store.state.componentes.modalHistoricoIsOpened;
                },
                set() {
                    return this.$store.commit('componentes/TOGGLE_MODAL_HISTORICO')
                }
            },
        },
        methods: {
            ...mapMutations('componentes', [
                'TOGGLE_MODAL_HISTORICO'
            ]),
            showModal() {
                this.modalHistoricoIsOpened = !this.modalHistoricoIsOpened
                if (this.modalHistoricoIsOpened && this.lista.length == 0) {
                    this.loadAsyncData()
                }
            },
            loadAsyncData() {                
                if(this.agendaLog){
                    this.$http.get('/api/manutencao/LogAgenda?chave=' + this.$props.chave)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.lista = data;                                
                            }
                        });
                }else{
                
                    this.$http.get('/api/manutencao/log?tabela=' + this.$props.tabela + "&chave=" + this.$props.chave)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.lista = data;                                
                            }
                        });
                }

            }
        }
    }
</script>