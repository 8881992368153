<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <div class="box">
            <b-tabs v-model="tabs" multiline>
                <b-tab-item label="Filtro">
                    <div class="columns is-multiline">
                        <div class="column is-6">
                            <searchIdName :id.sync="unidade.id" :item.sync="unidade" table="Unidade" :label="$t('MENUANALISE.UNIDADE')"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="localDeOrigem.id" :item.sync="localDeOrigem" table="Local" :label="$t('MENUANALISE.LOCALORIGEM')"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="localDeEntrega.id" :item.sync="localDeEntrega" table="Local" :label="$t('MENUANALISE.LOCALENTREGA')"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="paciente.id" :item.sync="paciente" table="Paciente" label="Paciente"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="medico.id" :item.sync="medico" table="Medico" label="Médico"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="convenio.id" :item.sync="convenio" table="Convenio" :label="$t('MENUANALISE.CONVENIO')"/>
                        </div>
                        <div class="column is-6 is-flex is-flex-direction-column">
                            <div>
                                <b-field :label="$t('MENUANALISE.SETOR')" style="margin-bottom: 0;">
                                    <b-autocomplete
                                        v-model="searchedSetor"
                                        :placeholder="$t('MENUANALISE.BUSCASETOR')"
                                        :data="listaSetorAutocomplete"
                                        :loading="isFetching"
                                        @keydown.native.enter.prevent="this.onSelectSetor()"
                                        @typing="procurarSetorAutocomplete"
                                        @select="option => {selectedSetor = option; this.onSelectSetor()}"
                                    >
                                        <template slot-scope="props">
                                            <div class="media">
                                                <div class="media-left">
                                                    <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                                </div>
                                                <div class="media-content">
                                                    {{ props.option.nome }}
                                                </div>
                                            </div>
                                        </template>
                                    </b-autocomplete>
                                </b-field>

                                <b-field>
                                    <b-select multiple v-model="selectedSetores" expanded>
                                        <option v-for="setor in setores" :key="setor.id" :value="setor.id">
                                            {{ setor.name }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>

                            <b-button class="is-align-self-center" style="margin: 0.5rem 0;" @click="removeSelectedSetores" type="is-danger">{{$t('MENUANALISE.REMOVESELECIONADOS')}}</b-button>
                        </div>
                        <div class="column is-6"></div>
                        <div class="column is-6">
                            <div class="panel">
                                <p class="panel-tabs">
                                    <a :class="{ 'is-active': dataFiltro == 'emissao' }" @click="dataFiltro = 'emissao'">{{$t('MENUANALISE.EMITIDO')}}</a>
                                    <a :class="{ 'is-active': dataFiltro == 'solicitacao' }" @click="dataFiltro = 'solicitacao'">{{$t('MENUANALISE.SOLICITACAO')}}</a>
                                    <a :class="{ 'is-active': dataFiltro == 'retorno' }" @click="dataFiltro = 'retorno'">Retorno</a>
                                </p>
                                <div class="panel-block">
                                    <periodoHora :dataInicial.sync="datas[0]"
                                        :dataFinal.sync="datas[1]"/>
                                </div>
                            </div>                    
                        </div>                
                        <div class="column is-6">
                            <b-field>
                                <b-checkbox v-model="apenasResultadosNaoImpressos">{{$t('MENUANALISE.RESULTADOSNAOIMPRESSOS')}}</b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="apenasResultadosSemEmailMedico">{{$t('MENUANALISE.RESULTADOSSEMEMAILMEDICO')}}</b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="apenasResultadosSemEmailPaciente">{{$t('MENUANALISE.RESULTADOSSEMEMAILPACIENTE')}}</b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="apenasResultadosSemEmailConvenio">{{$t('MENUANALISE.RESULTADOSSEMEMAILCONVENIO')}}</b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="apenasResultadosSemVisualizacao">{{$t('MENUANALISE.RESULTADOSSEMVISUALIZACAO')}}</b-checkbox>
                            </b-field>                                                       
                            <b-field>
                                <b-checkbox v-model="guiasApenasCompletas">{{$t('MENUANALISE.APENASGUIASCOMPLETAS')}}</b-checkbox>
                            </b-field> 
                            <div class="columns is-multiline">
                                <div class="column is-4">
                                    <b-radio v-model="ordena"
                                        name="ordena"
                                        title="Ordenar"
                                        native-value="guia">
                                        Nro da Guia
                                    </b-radio>
                                </div>
                                <div class="column is-4">
                                    <b-radio v-model="ordena"
                                        name="ordena"
                                        title="Ordenar"
                                        native-value="paciente">
                                        Paciente
                                    </b-radio>
                                </div>
                                <div class="column is-4">
                                    <b-radio v-model="ordena"
                                        name="ordena"
                                        title="Ordenar"
                                        native-value="emissao">
                                        {{$t('MENUANALISE.DATAEMISSAO')}}
                                    </b-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="has-text-centered">
                        <b-button type="is-success" :loading="loading" @click="carregarGuias()">Filtrar</b-button>
                    </div>
                </b-tab-item>
                <b-tab-item label="Selecionados">
                    <article>
                        <div>
                            <div class="is-light">
                                <label><strong>Código da Guia</strong></label>
                                <div class="field has-addons">
                                    <div class="control">
                                        <input type="text" v-model="guiaId" class="input" ref="guiaId"/>
                                    </div>
                                    <div class="control">
                                        <a class="button" @click="incluirGuia();">
                                        Incluir
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </b-tab-item>
            </b-tabs>
        </div>
        
        <div class="box" v-if="model.length > 0">
            <b-table :data="model"
                 :loading="loading"
                 striped
                 hoverable
                :checked-rows.sync="checkedRows"
                checkable
                :checkbox-position="checkboxPosition">

                <template >
                    <b-table-column  v-slot="props" field="Número da Guia" :label="$t('MENUANALISE.NUMERODAGUIA')">
                        <router-link :to="{ name: 'guia', params: { id: props.row.id }}">
                            {{ props.row.id }}
                        </router-link>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Referencia" label="Referencia">
                        <small>{{ props.row.referencia }}</small>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Paciente" label="Paciente">
                        {{ props.row.pacienteNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Convenio" :label="$t('MENUANALISE.CONVENIO')">
                        {{ props.row.convenioNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Medico" label="Solicitante">
                        {{ props.row.medicoNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Local de Origem" :label="$t('MENUANALISE.LOCALORIGEM')">
                        {{ props.row.localDeOrigemNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Emissao" :label="$t('MENUANALISE.EMISSAO')">
                        <span v-if="props.row.dataDeEmissao">
                            {{ $moment(props.row.dataDeEmissao).format("DD/MM/YYYY") }}
                        </span>
                    </b-table-column>

                </template>
            </b-table>
        </div>
        <br>
        <nav class="level" v-if="model.length > 0">
            <div class="level-item">
                <div class="buttons">
                    <b-button type="is-info"
                                :loading="loading"
                                @click="enviarEmailPaciente()"
                                icon-left="check-circle">
                        Enviar por email paciente
                    </b-button>
                    <b-button type="is-info"
                                :loading="loading"
                                @click="enviarEmailMedico()"
                                icon-left="check-circle">
                        Enviar por email solicitante
                    </b-button>
                    <b-button type="is-info"
                                :loading="loading"
                                @click="enviarEmailConvenio()"
                                icon-left="check-circle">
                                {{$t('MENUANALISE.ENVIAREMAILCONVENIO')}}
                    </b-button>
                    <b-button v-if="config.imprimeResultadosLoteViaBrowser" type="is-success"
                        :loading="loading"
                        @click="imprimirEmLoteViaBrowser"
                        icon-left="check-circle"
                    >
                        {{ $t('MENUANALISE.IMPRIMIRLOTE') }}
                    </b-button>
                    <form v-else action="/Home/ImpressaoPdf" method="post">
                        <input type="hidden" name="apenasNaoImpressos" :value="apenasResultadosNaoImpressos" />
                        <input type="hidden" name="descricao" value="Resultado" />
                        <input type="hidden" name="loteResultados" value="true" />
                        <input type="hidden" name="setores" v-model="setoresIds" />
                        
                        <input v-for="(item, index) in checkedRows" v-bind:key="index" type="hidden" name="guiaExames" :value="item.id" />

                        <b-button type="is-success"
                                :loading="loading"
                                native-type="submit"
                                icon-left="check-circle">
                            {{ $t('MENUANALISE.IMPRIMIRLOTE') }}
                        </b-button>
                    </form>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                                {{$t('MENUANALISE.VOLTAR')}}
                    </b-button>
                </div>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import periodoHora from '@/components/periodoHora.vue'
    import debounce from 'lodash/debounce'

    export default {
        data() {
            return {
                model: [],
                unidade: {
                    id: null
                },
                localDeOrigem: {
                    id: null
                },
                localDeEntrega: {
                    id: null
                },
                paciente: {
                    id: null
                },
                medico: {
                    id: null
                },
                convenio: {
                    id: null
                },
                datas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                dataFiltro: 'emissao',
                loading: false,
                checkedRows: [],
                checkboxPosition: 'left',
                ordena: 'guia',
                apenasResultadosNaoImpressos: false,
                apenasResultadosSemEmailMedico: false,
                apenasResultadosSemEmailPaciente: false,
                apenasResultadosSemEmailConvenio: false,
                apenasResultadosSemVisualizacao: false,
                guiasApenasCompletas: false,
                tabs: 0,
                guiaId: "",

                searchedSetor: '',
                setores: [],
                selectedSetor: null,
                selectedSetores: [],
                isFetching: false,
                listaSetorAutocomplete: [],
                setoresIds: null
            };
        },
        components: {
            titlebar,
            searchIdName,
            periodoHora
        },
        computed: {
            titleStack() {
                return [
                    'Análise',
                    this.$t("MENUANALISE.LOTERESULTADOS"),
                ]
            },          
            ...mapState([
                'unidadeId',
                'config',
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            carregarGuias(){
                
                const params = [
                    `ordenarPor=${this.ordena}`,
                    `filtrarPor=${this.dataFiltro}`,
                    `retornarLista=true`,
                    `dataInicial=${this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss') }`,
                    `dataFinal=${this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`
                ]

                if(this.unidade != null && this.unidade.id != null){
                    params.push(`unidadeId=${this.unidade.id}`);
                }

                if(this.localDeOrigem != null && this.localDeOrigem.id != null){
                    params.push(`localDeOrigemId=${this.localDeOrigem.id}`);
                }

                if(this.localDeEntrega != null && this.localDeEntrega.id != null){
                    params.push(`localDeEntregaId=${this.localDeEntrega.id}`);
                }

                if(this.paciente != null && this.paciente.id != null){
                    params.push(`pacienteId=${this.paciente.id}`);
                }

                if(this.medico != null && this.medico.id != null){
                    params.push(`medicoId=${this.medico.id}`);
                }

                if(this.convenio != null && this.convenio.id != null){
                    params.push(`convenioId=${this.convenio.id}`);
                }

                if (this.apenasResultadosNaoImpressos) {
                    params.push('apenasNaoImpressos=true')
                }

                if (this.apenasResultadosSemEmailMedico) {
                    params.push('apenasSemEmailMedico=true')
                }

                if (this.apenasResultadosSemEmailPaciente) {
                    params.push('apenasSemEmailPaciente=true')
                }

                if (this.apenasResultadosSemEmailConvenio) {
                    params.push('apenasSemEmailConvenio=true')
                }

                if (this.guiasApenasCompletas) {
                    params.push('apenasGuiasCompletas=true')
                }

                if (this.apenasResultadosSemVisualizacao) {
                    params.push('apenasResultadosSemVisualizacao=true')
                }                

                if(this.setores.length > 0){
                    const setoresId = this.setores.map(setor => setor.id).join(",");
                    this.setoresIds = setoresId;
                    params.push(`setoresId=${setoresId}`);
                }
                                
                this.loading = true;
                this.$http.get(`/api/Analitico/LoteResultados?${params.join('&')}`)
                .then(({ data }) => {
                    this.model = data
                    this.checkedRows = data
                        this.loading = false
                    })
                    .catch((error) => {
                        this.loading = false
                        throw error
                    })

            },
            enviarEmailPaciente(){
                this.loading = true;
                const guiaExames = this.checkedRows.map(item => item.id);
                const setoresIds = this.setoresIds ?? '';
                this.$http.post(`/api/analitico/EmailPacienteLote?setoresIds=${setoresIds}`, guiaExames)
                    .then(() => {
                        this.loading = false;
                    })
            },
            enviarEmailMedico(){
                this.loading = true;
                const guiaExames = this.checkedRows.map(item => item.id);
                const setoresIds = this.setoresIds ?? '';
                this.$http.post(`/api/analitico/EmailMedicoLote?setoresIds=${setoresIds}`, guiaExames)
                    .then(() => {
                        this.loading = false;
                    })
            },
            enviarEmailConvenio(){
                this.loading = true;
                const guiaExames = this.checkedRows.map(item => item.id);
                const setoresIds = this.setoresIds ?? '';
                this.$http.post(`/api/analitico/EmailConvenioLote?setoresIds=${setoresIds}`, guiaExames)
                    .then(() => {
                        this.loading = false;
                    })
            },
            incluirGuia(){
                this.loading = true;

                this.$http.get('/api/atendimento/BuscarGuia?id=' + this.guiaId)
                .then(res => res.data)
                .then(data => {                    

                    data.dataDeEmissao = new Date(data.dataDeEmissao);                    

                    this.model.push(data);
                    this.checkedRows.push(data);

                    this.$refs.guiaId.focus();
                    this.loading = false;
                })
                .catch(e => {
                    this.$buefy.snackbar.open({
                        duration: 2000,
                        message: 'Guia ' + this.guiaId + ' não encontrada',
                        type: 'is-danger',
                        position: 'is-top-left',
                        queue: false
                    })
                })
                .finally(() => this.guiaId = "");
            },
            procurarSetorAutocomplete: debounce(function (name) {
                if (!name.length) {
                    this.listaSetorAutocomplete = []
                    return
                }
                this.isFetching = true

                const params = [
                    `nome=${name}`,
                ].join('&')
                this.$http.get(`/search/setor?${params}`)
                    .then(({ data }) => {
                        this.listaSetorAutocomplete = data
                    })
                    .catch((error) => {
                        this.listaSetorAutocomplete = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),
            onSelectSetor() {
                this.setores.push({
                    id: this.selectedSetor.id,
                    name: this.selectedSetor.nome
                });
            },
            removeSelectedSetores() {
                this.setores = this.setores.filter(setor => !this.selectedSetores.includes(setor.id));
                this.selectedSetores = []
            },
            imprimirEmLoteViaBrowser() {
                alert(this.$t("MENUANALISE.IMPRIMIRLOTEALERTA"))
                const setoresIds = this.setoresIds ?? '';
                window.open(`/api/Analitico/ImprimirEmLote?guiaIds=${this.checkedRows.map(item => item.id).join(',')}&setoresIds=${setoresIds}`);
            }
        }
    }
</script>