<template>
    <section>

        <b-modal :active.sync="isFiltroModalActive"
                 full-screen>
            <filtros :tipo="'Convenio'" @filtrar="filtrar($event)" @fecharModal="fecharModal()"></filtros>
        </b-modal>

        <boasVindas></boasVindas>

        <pesquisas class="mb-2" :pesquisas="pesquisas" :tipo="'Convenio'"></pesquisas>

        <div v-if="isLoading">
            <div class="box">
                <div class="panel">
                    <div class="panel-block columns is-multiline is-mobile">
                        <div class="column is-10-desktop">
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-6-desktop is-full-mobile">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                                <div class="column is-2-desktop is-half-mobile">
                                    <b-skeleton width="60%" animated></b-skeleton>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                                <div class="column is-2-desktop is-half-mobile">
                                    <b-skeleton width="60%" animated></b-skeleton>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                </div>
                            </div>
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-full-mobile">
                                    <b-skeleton width="80%" animated></b-skeleton>
                                </div>
                            </div>
                        </div>
                        <div class="column is-full-mobile has-text-centered">
                            <div class="columns is-multiline is-mobile">
                                <b-skeleton width="80%" animated></b-skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <guias v-if="model != null" @pageChange="pageChange($event)" :lista.sync="model.guias" :tipo="'Convenio'" :page="page" :pageCount="pageCount"
                @filtrar="filtrarModal()" @ordenar="ordenar($event)" :ordenar.sync="filter.ordenarPor"></guias>
        </div>

    </section> 
</template>

<style>
    .alertas {
        cursor: pointer;
    }
</style>

<script>
    import { mapState } from 'vuex';
    import boasVindas from '@/components/portal/boas-vindas.vue';
    import pesquisas from '@/components/portal/pesquisas.vue';
    import filtros from '@/components/portal/filtros.vue';
    import guias from '@/components/portal/guias.vue';
    import filterValidate from '../utils/filterValidate';
    
    export default {
        data(){
            return {
                model: null,
                page: 1,
                pageCount: null,
                pesquisas: [],
                total: null,
                filter: {
                    ordenarPor: 'emissao'
                },
                isLoading: false,
                avisosCount: 0,
                recoletasCount: 0,
                examesAtrasosCount: 0,
                valorCriticoReportadoCount: 0,
                integracaoCount: 0,                                                            
                loadingAvisosCount: true,
                loadingRecoletasCount: true,
                loadingExamesAtrasadosCount: true,
                loadingValorCriticoReportadoCount: true,
                loadingIntegracaoCount: true,
                activeTab: 0,
                isFiltroModalActive: false,
                pesquisou: false
            }
        },
        computed: {
            ...mapState([
                'config',
                'usuario',
            ])          
        },
        components: {
            boasVindas,
            pesquisas,
            filtros,
            guias,                        
        },
        methods: {
                                  
            carregarLista(filtrosUrl){
                this.isLoading = true;
                this.model = null;

                this.$http.get('/api/portal/c' + filtrosUrl)
                    .then(res => {
                        this.model = res.data;
                        this.page = this.model.page;
                        this.pageCount = this.model.pageCount;
                        this.total = this.pageCount * 10;
                        this.isLoading = false;
                    });
            },
            carregarPesquisas(){
                this.$http.get('/api/portal/ConvenioPesquisasSatisfacao')
                    .then(res => {
                        this.pesquisas = res.data;
                    });
            },    
            filtrar(filtros){
                let periodoPesquisa = null;
            
                if(filtros.datas != null)
                    periodoPesquisa = this.$moment(filtros?.datas[1]).diff(this.$moment(filtros.datas[0]), 'days');

                if(periodoPesquisa > this.config.periodoFiltro && filterValidate(filtros)){
                    this.$buefy.dialog.alert(this.$t('SISTEMA.INFOPERIODO', {'0': this.config.periodoFiltro}));
                    return;
                }

                filtros.ordenarPor = this.filter.ordenarPor;

                this.filter = filtros;

                let filtrosUrl = '?page=' + this.page;

                if(filtros.filtrarPor){
                    filtrosUrl += '&filtrarPor=' + filtros.filtrarPor;
                }

                if(filtros.ordenarPor){
                    filtrosUrl += '&ordenarPor=' + filtros.ordenarPor;
                }

                if(filtros.datas){
                    filtrosUrl += '&dataInicial=' + this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') + 
                    '&dataFinal=' + this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss');
                }

                if(filtros.paciente != null){
                    filtrosUrl += '&nomePaciente=' + filtros.paciente;
                }

                if(filtros.local != null && filtros.local.id != null){
                    filtrosUrl += '&localDeOrigemId=' + filtros.local.id;
                }

                //Se existir estes dados significa que realizou uma pesquisa
                if(filtros.paciente != null && filtros.filtrarPor && filtros.datas){
                    if(!this.pesquisou){
                        filtrosUrl = filtrosUrl.replace(`?page=${this.page}`, '?page=1') //Troca para a primeira página, para ser listada depois de pesquisar
                        this.pesquisou = true;
                    }
                }

                if(filtros.referencia) {
                    filtrosUrl += '&referencia=' + filtros.referencia;
                }

                this.carregarLista(filtrosUrl);

                this.isFiltroModalActive = false;
            },
            filtrarModal(){
                this.isFiltroModalActive = true;
            },
            fecharModal(){
                this.isFiltroModalActive = false;
            },            
            ordenar(ordenar){
                this.filter.ordenarPor = ordenar;
                this.filtrar(this.filter);
            },
            pageChange(page){
                this.page = page;
                if(this.filter != null){
                    this.filtrar(this.filter);
                }
                else {
                    this.carregarLista('?page=' + page);
                }
                
            },
            carregarAvisosCount(){
                this.$http.get('/api/portal/AvisosCount')
                    .then(res => {
                        this.avisosCount = res.data;
                        this.loadingAvisosCount = false;
                    });
            },
            carregarRecoletasCount(){
                this.$http.get('/api/portal/RecoletasCount')
                    .then(res => {
                        this.recoletasCount = res.data;
                        this.loadingRecoletasCount = false;
                    });
            },
            carregarExamesAtrasadosCount(){
                this.$http.get('/api/portal/ExamesAtrasosCount')
                    .then(res => {
                        this.examesAtrasosCount = res.data;
                        this.loadingExamesAtrasadosCount = false;
                    });
            },
            carregarValorCriticoReportadoCount(){
                this.$http.get('/api/portal/ValorCriticoReportadoCount')
                    .then(res => {
                        this.valorCriticoReportadoCount = res.data;
                        this.loadingValorCriticoReportadoCount = false;
                    });
            },            
            carregarIntegracaoCount(){
                this.$http.get('/api/portal/IntegracaoCount')
                    .then(res => {
                        this.integracaoCount = res.data;
                        this.loadingIntegracaoCount = false;
                    });
            },

        },
        created() {

            this.carregarLista('?page=' + this.page);
            
            this.carregarPesquisas();
            this.carregarAvisosCount();
            this.carregarRecoletasCount();
            this.carregarExamesAtrasadosCount();
            this.carregarIntegracaoCount();
            this.carregarValorCriticoReportadoCount();

        }
    }
</script>