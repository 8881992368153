<template>
    <section>              
        <filtros :tipo="'Resultados'" @filtrar="filtrar($event)"></filtros>  
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>

            <div v-if="(model!=null && model.lista.length>0) && !usuario.escondeResultado" class=" box columns has-text-centered">
                <div class="column is-11">                
                    <p class="title">{{$t('RESULTADOS.RESULTADOSPERIODO')}}{{model.sub}}</p>
                </div>
                <div class="column is-1 is-full-mobile has-text-right has-text-centered-mobile">
                    <b-button icon-left="download"
                              :title="$t('RESULTADOS.IMPRIMIRRESULTADO')"
                              @click="downloadPdf()">
                    </b-button>
                </div>
            </div>

            <br>

            <b-table v-if="(model!=null && model.lista.length > 0)" :data="model.lista"
                    :loading="isLoading"
                    striped
                    hoverable
                    :checked-rows.sync="checkedRows"
                    checkable
                    :checkbox-position="checkboxPosition">

                <template>
                    <b-table-column v-slot="props" field="guiaId" :label="$t('RESULTADOS.GUIA')">
                        <router-link class="is-block" :to="{ name: 'portalconvenioguia', params: { id: props.row.guiaId }}" >
                            {{ props.row.guiaId }}
                        </router-link>                                    
                        
                    </b-table-column>

                    <b-table-column v-slot="props" field="referencia" :label="$t('RESULTADOS.REFERENCIA')">
                        {{ props.row.referencia }}
                    </b-table-column>

                    <b-table-column v-slot="props" field="guiaEmissao" :label="$t('RESULTADOS.EMISSAO')">
                        {{ $moment(props.row.guiaEmissao).format("DD/MM/YYYY HH:mm") }}
                    </b-table-column>

                    <b-table-column v-slot="props" field="pacienteId" :label="$t('RESULTADOS.PACIENTEID')">
                        {{ props.row.pacienteId }}
                    </b-table-column>

                    <b-table-column v-slot="props" field="pacienteNome" :label="$t('RESULTADOS.PACIENTENOME')">
                        {{ props.row.pacienteNome }}
                    </b-table-column>

                    <b-table-column v-slot="props" field="localDeOrigemNome" :label="$t('RESULTADOS.LOCAL')">
                        {{ props.row.localDeOrigemNome }}
                    </b-table-column>   

                    <b-table-column v-slot="props" field="Liberacao" :label="$t('RESULTADOS.LIBERACAO')" style="display: flex;">

                        <b-tooltip :label="$t('RESULTADOS.LIBERACAOINFO')" multilined>    
                            <span v-if="props.row.complitudeGuia==1" class="tag is-pulled-right is-dark">{{$t('RESULTADOS.NENHUM')}}</span>
                            
                            <span v-else-if="props.row.complitudeGuia==2" class="tag is-pulled-right" style="background-color:goldenrod">{{$t('RESULTADOS.PARCIAL')}}</span>
                            
                            <span v-else-if="props.row.complitudeGuia==3"  class="tag is-pulled-right " style="background-color:darkgreen;color: white;">{{$t('RESULTADOS.TOTAL')}}</span>
                        </b-tooltip>
                        

                    </b-table-column>                             
                    
                </template>
            </b-table>
        </div>
    </section> 
</template>

<script>
    import { mapState } from 'vuex'    
    import filtros from '@/components/portal/filtros.vue';
    import filterValidate from '../utils/filterValidate';

    export default {
        data() {
            return {
                model: null,                
                isLoading: false,
                checkboxPosition: 'left',
                checkedRows: [],                
                
            }
        },
        computed: {              
            ...mapState([
                'config',
                'usuario'
            ])
        },
        components: {            
            filtros
        },
        methods: {

            downloadPdf(){
                let guiasIds = ''
                this.checkedRows.forEach(x => {         
                    guiasIds += x.guiaId+',';                
                })

                if(this.model.guiaLiberada && this.checkedRows.filter(x => x.complitudeGuia != 3).length > 0){
                    return this.$buefy.toast.open({
                        duration: 3000,
                        message: 'Guias incompletas selecionadas, permitidas apenas guias com liberação: Total.',
                        type: 'is-danger'
                    })
                }

                if (guiasIds == '') {
                    this.$buefy.toast.open({
                        message: 'Nenhum resultado selecionado',
                        type: 'is-danger'
                    })
                } else {
                    window.open('/api/portal/LoteResultadosZip?idList=' + guiasIds, '_blank')
                }
            },             

            abrirGuia(guiaId) {                                      
                const routeData =  this.$router.resolve({ name: 'portalconvenioguia', params: { id: guiaId } });                 
                window.open(routeData.href, '_blank');                                                                              
            },  
                        
            filtrar(filtros){             
                const periodoPesquisa = this.PeriodoPesquisa(filtros);
                let existeFiltro = false;

                if(filtros.datas[0] && filtros.datas[1]){
                    existeFiltro = true;
                }

                if(periodoPesquisa > this.config.periodoFiltro && filterValidate(filtros)){
                    this.$buefy.dialog.alert(this.$t('SISTEMA.INFOPERIODO', {'0': this.config.periodoFiltro}));
                    return;
                }

                let filtrosUrl =  '?dataInicial=' + this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') + 
                    '&dataFinal=' + this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss')+ '&filtrarPor=' + filtros.filtrarPor;                    


                if(filtros.local.id != null){
                    filtrosUrl += '&localDeOrigemId=' + filtros.local.id;
                    existeFiltro = true;
                }

                if(filtros.paciente != null){
                    filtrosUrl += '&nomePaciente=' + filtros.paciente;
                    existeFiltro = true;
                }

                if(filtros.somentePortal){
                    filtrosUrl += '&somentePortal=true';
                    existeFiltro = true;
                }

                if(filtros.somenteNaoImpressos){
                    filtrosUrl += '&somenteNaoImpressos=true';
                    existeFiltro = true;
                }

                if(filtros.referencia) {
                    filtrosUrl += '&referencia=' + filtros.referencia;
                }
                
                if(!existeFiltro){
                    this.$buefy.dialog.alert('Utilize ao menos um filtro para pesquisa.');
                    return;
                }

                this.carregarLista(filtrosUrl);
            },            
            carregarLista(filtrosUrl){

                this.isLoading = true;
                this.$http.get('/api/portal/RelatorioDeResultados' + filtrosUrl)
                    .then(res => {
                        this.model = res.data;                                              
                        this.isLoading = false;                        
                    });


            },
            PeriodoPesquisa(filtros){
                const differenceInTime = filtros.datas[1].getTime() - filtros.datas[0].getTime();
                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                
                return differenceInDays;
            }
        }       
    }
</script>