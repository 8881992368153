<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger" v-if="tipoAgendamento">
            <p class="modal-card-title">{{$t('MODALBLOQUEIO.BLOQUEARHORARIO')}} - {{ tipoAgendamento?.nome }}</p>
        </header>
        <header class="modal-card-head has-bg-danger" v-else>
            <p class="modal-card-title">{{$t('MODALBLOQUEIO.BLOQUEARHORARIO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification v-if="erroBloqueioHorarios"
                            type="is-danger">
                {{ erroBloqueioHorarios }}
            </b-notification>
            <div class="panel">       
                <p class="panel-block">
                    <b-field :label="$t('MODALBLOQUEIO.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-select expanded v-model="motivoBloqueioHorariosSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosBloqueioHorarios" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                    :loading="salvandoDados"
                      @click="confirmarBloqueioHorarios">{{$t('MODALBLOQUEIO.CONFIRMARBLOQUEIO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('MODALBLOQUEIO.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';    

    export default {
        props: {
            tipoAgendamento: Object,
            dataHoraFim: Date,
            evento: Object,
        },
        data() {
            return {
                procurarMotivosBloqueioHorarios: '',
                listaMotivosBloqueioHorarios: [],
                motivoBloqueioHorariosSelecionado: null,
                erroBloqueioHorarios: null,
                acaoBloquear: true,        
                salvandoDados: false,
            }
        },
        computed: {
            filteredMotivosBloqueioHorarios() {
                return this.procurarMotivosBloqueioHorarios
                    ? this.listaMotivosBloqueioHorarios
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosBloqueioHorarios.toUpperCase()) > -1)
                    : this.listaMotivosBloqueioHorarios
            },
        },
        mounted() {
            this.loadListaMotivosBloqueioHorarios()
        },
        methods: {
            loadListaMotivosBloqueioHorarios() {
                this.$http.get('/api/search/MotivosBloqueiosAgenda')
                    .then(m => {
                        this.listaMotivosBloqueioHorarios = m.data
                    })
            },
            async confirmarBloqueioHorarios() {
                if(this.motivoBloqueioHorariosSelecionado){
                    this.salvandoDados = true;
                    const params = {
                        tipoAgendamentoId: this.tipoAgendamento?.id ?? this.evento.tipoAgendamentoId,
                        acaoBloquear:this.acaoBloquear,
                        dataInicial:this.$moment(this.evento.dataHora).format('YYYY-MM-DDTHH:mm:ss'),
                        dataFinal:this.dataHoraFim,
                        motivoId:this.motivoBloqueioHorariosSelecionado,
                        eventos: [this.evento],
                        modalBloquearHorarios: true
                    }
                    console.log(params, 'params')
                    this.$http.post('/api/agenda/BloquearHorarios', params)
                    .then((res) => {
                        if (res.body.erro) {
                            this.erroBloqueioHorarios = res.body.erro;
                            this.salvandoDados=false;
                        } else {
                            this.$emit('loadDataDia');
                            this.$emit('close')
                        }                        
                    })
                    .finally(() =>{
                        this.salvandoDados=false;
                    }); 
                } else {
                    this.$buefy.dialog.alert({
                        title: this.$t('MODALBLOQUEIO.MOTIVO'),
                        message: this.$t('MODALBLOQUEIO.AVISOMOTIVO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                }                      
            }
        }
    }
</script>