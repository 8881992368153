<template>
    <div class="column is-12">
        <div :class="classes" @click.capture="selecionar">
            
            <div v-if="amostra.guiaId>0" class="column is-1">
                <small>Guia:</small>
                <br />
                <router-link class="is-block" :to="{ name: 'portalconvenioguia', params: { id: amostra.guiaId  }}" >
                    {{ this.amostra.guiaId  }}
                </router-link>  
            </div>            

            <div class="column is-2" style="display: inline;"> 
                <small>{{$t('AMOSTRA.CODIGOBARRAS')}}:</small>
                <br />
                <b class="guiaAmostraCodigoDeBarrasItem">{{this.amostra.codigoDeBarras}}</b>
            </div> 

            <div :class="{ 'column is-1' : (amostra.guiaId>0 || coletaExternaPortal ) ,'column is-1': ( ( amostra.guiaId<=0 || amostra.guiaId==null) && !coletaExternaPortal )}">
                
                <b-tooltip  :label="$t('AMOSTRA.AMOSTRACANCELADA')" placement="bottom" position="is-bottom" type="is-dark">
                    <b-icon v-if="this.amostra.cancelada"
                        icon="close-circle"
                        size="is-medium"
                        class="is-pulled-right"
                        type="is-danger"></b-icon>
                </b-tooltip>

                
                <b-tooltip :label="$t('AMOSTRA.AMOSTRAEXAMECANCELADA')" placement="bottom" position="is-bottom" type="is-dark">
                    <b-icon v-if="this.amostra.invalidada"
                        icon="asterisk"
                        size="is-medium"
                        class="is-pulled-right"
                        type="is-danger"></b-icon>
                </b-tooltip>
                
                
                <b-tooltip :label="$t('AMOSTRA.AMOSTRAINCIDENCIA')" placement="bottom" position="is-bottom" type="is-dark">
                    <b-icon v-if="this.amostra.incidencia"
                        icon="alert-rhombus"
                        size="is-medium"
                        class="is-pulled-right"
                        type="is-warning"></b-icon>
                </b-tooltip>
            
            
                

                
            </div>

            <div class="column is-1">
            <b-tooltip :label="$t(amostra.statusTitle)" placement="bottom" position="is-bottom" type="is-dark">
                <b-tag class="has-text-weight-bold" :style="amostra.statusHtmlStyle">
                    {{ this.amostra.statusSigla }}
                </b-tag>                
            </b-tooltip>
            </div>            

            <div v-if="mostraPaciente" :class="{ 'column is-2' : (amostra.amostraLote>0) ,'column is-4': (amostra.amostraLote<=0 || amostra.amostraLote==null ) }" >
                <small>{{$t('SISTEMA.PACIENTE')}}:</small>
                <br />
                <b>{{this.amostra.pacienteNome}}</b>
            </div>

            <div class="column is-2">
                <small>{{$t('AMOSTRA.MATERIAL')}}:</small>
                <br />
                <span>
                    {{this.amostra.material.nome}}
                </span>
            </div>
            <div class="column is-2">
                <small>{{$t('AMOSTRA.CONSERVANTE')}}:</small>
                <br />
                <span>
                    {{this.amostra.conservante.nome}}
                </span>
            </div>

            <div v-if="amostra.codigoEtiquetaPreColeta != null" class="column is-2">
                <small>{{$t('AMOSTRA.CODIGOETIQUETA')}}:</small>
                <br />
                <span>
                    {{this.amostra.codigoEtiquetaPreColeta}}
                </span>
            </div>

            <div v-if="amostra.codigoIntegracaoBarras != null  && geraEtiquetaPreColeta == false" class="column is-2">
                <small>{{$t('AMOSTRA.CODIGOINTEGRACAO')}}:</small>
                <br />
                <span>
                    {{this.amostra.codigoIntegracaoBarras}}
                </span>
            </div>

            <div v-if="amostra.amostraLote>0" class="column is-2">
                <small>{{$t('AMOSTRA.LOTE')}}:</small>
                <br />
                <span>
                    {{this.amostra.amostraLote}} 
                    <small>{{this.$moment(this.amostra.amostraLoteData).format('DD/MM/YYYY HH:mm') }}</small>
                </span>
            </div>            

            <div class="column is-1 is-pulled-right">
                <b-tag v-if="this.amostra.numero" type="is-dark" rounded>{{this.amostra.numero}}</b-tag>
            </div>

            <div class="column is-1 is-pulled-right">
                

                <b-tooltip :label="$t('AMOSTRA.CONGELADO')" placement="bottom" position="is-bottom" type="is-dark">
                 <span v-if="this.amostra.acondicionamento == 'Congelada'" class="tag is-pulled-right is-light">C</span>
                </b-tooltip>

                
                <b-tooltip :label="$t('AMOSTRA.REFRIGERADA')" placement="bottom" position="is-bottom" type="is-dark">
                <span v-if="this.amostra.acondicionamento == 'Refrigerada'" class="tag is-pulled-right is-link">R</span>
                </b-tooltip>
                

                <b-tooltip :label="$t('AMOSTRA.AMBIENTE')" placement="bottom" position="is-bottom" type="is-dark">
                <span v-if="this.amostra.acondicionamento == 'Ambiente'" class="tag is-pulled-right is-primary">A</span>
                </b-tooltip>

                <span v-if="amostra.guiaId>0" style="margin-left:5px;" >

                <b-tooltip :label="$t('AMOSTRA.ETIQUETA')" placement="bottom" position="is-bottom" type="is-dark">
                <span v-if="this.amostra.amostraImpressao"><b-icon :icon="'printer'" type="is-success" ></b-icon></span>                        
                </b-tooltip>
                </span>                
            </div>

            <div v-for="(item, index) in this.amostra.itens" :key="index">
                <b-tooltip :label="$t(item.exame.nome)" placement="bottom" position="is-bottom" type="is-dark">
                    <b-tag>
                        {{ item.exame.apelido }}
                    </b-tag>
                </b-tooltip>
            </div>

            <div class="column" v-if="geraEtiquetaPreColeta">
                <b-field :label="$t('AMOSTRA.IDENTIFICACAO')">
                    <b-input type="text" v-model="amostra.codigoEtiquetaPreColeta"></b-input>
                </b-field>
            </div>

            <div class="column" v-if="coletaExternaPortal">
                <b-field :label="$t('AMOSTRA.PORTALCOLETADOR')">
                    <b-select v-if="amostra.portalColetadorId && dadosSalvos" v-model="amostra.portalColetadorId" disabled>
                            <option :value="amostra.portalColetadorId">
                                {{amostra.portalColetadorNome}}
                            </option>
                    </b-select>
                    <b-select v-else v-model="amostra.portalColetadorId" :key="index">
                        <option :value="null"></option>
                        <option v-for="(item, index) in coletadoresConvenio" :key="index" :value="item.id">
                            {{item.nome}}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div class="column" v-if="coletaExternaPortal">
                <b-field :label="$t('AMOSTRA.PORTALCOLETADORDATA')">                    
                    <b-datetimepicker v-if="amostra.portalColetadorData && dadosSalvos" position= "is-bottom-left" v-model="amostra.portalColetadorData" disabled></b-datetimepicker>
                    <b-datetimepicker v-else position= "is-bottom-left" v-model="amostra.portalColetadorData"></b-datetimepicker>
                </b-field>
            </div>                        
        </div>

    </div>
</template>

<script>

    import { mapState } from 'vuex'

    export default {
        props: {
            amostra: Object,
            index: Number,
            selecionada: Boolean,
            mostraPaciente: Boolean,
            isPortal: Boolean,
            geraEtiquetaPreColeta: Boolean,               
            coletaExternaPortal: {
                type: Boolean,
                default: false
            }             
        },
        
        data() {
            return {
                classes: this.defineClasses(),                
                coletadoresConvenio:[],
                dadosSalvos: false
            };
        },
        created(){

            if(this.coletaExternaPortal){
            
                if(this.amostra.portalColetadorNome){
                    this.dadosSalvos = true
                }
                
                const params = [
                    `convenioId=${this.usuario.convenioId}`,
                    `listarTodos=${true}`,
                ].join('&');

                this.$http.get(`/api/portal/ColetadoresPortalApoiado?${params}`)
                    .then(res => {
                        this.coletadoresConvenio = res.data.filter(coletador => !coletador.desativado);
                    }) 
            }
         
        },
        computed: {    
            ...mapState([                
                'usuario'
            ])
        },        
        watch: {
            selecionada: function () {
                this.classes = this.defineClasses()
            }
        },
        methods: {
            defineClasses() {
                return "columns is-multiline guiaAmostra" +
                    (this.amostra.incidencia ? " guiaAmostraComIncidencia" : "") +
                    (!this.amostra.itens.map(x => !x.cancelado).length > 0 ? " guiaAmostraInvalidada" : "") +
                    (this.amostra.cancelada ? " guiaAmostraCancelada" : "") +
                    (this.selecionada ? " guiaAmostraSelecionada" : "")
            },
            selecionar() {
                if (this.amostra.id != 0) {
                    this.$emit('selecionarAmostra', this.amostra)
                }
            },
            exameStatus(item) {
                return item.cancelado
                    ? "is-danger"
                    : item.resultadoAssinado
                        ? "is-success"
                        : item.resultadoDigitado
                            ? "is-info"
                            : ""
            },
            exameStatusTitle(item) {
                return item.cancelado
                    ? this.$t('AMOSTRA.EXAMECANCELADO')
                    : item.resultadoAssinado
                        ? this.$t('AMOSTRA.EXAMEASSINADO')
                        : item.resultadoDigitado
                            ? this.$t('AMOSTRA.EXAMEDIGITADO')
                            : ""
            }            
        }
    }

</script>