<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALRETIFICALAUDO.RETIFICACAO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <p>{{$t('MODALRETIFICALAUDO.NOTIFICAÇÃO')}}</p>
            </b-notification>
            <b-notification v-if="erroCancelamentoExame"
                            type="is-danger">
                {{ erroCancelamentoExame }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALRETIFICALAUDO.EXAMES')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-tag type="is-info" v-for="(exame,index) in selecionado" :key="index">{{ exame.exameApelido }}</b-tag>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('MODALRETIFICALAUDO.MOTIVOS')">
                    </b-field>
                </p>

                <div class="panel-block">
                    <b-select v-model="motivoRetificacaoSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosCancelamentoExame" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="confirmarRetificacao">{{$t('MODALRETIFICALAUDO.CONFIRMARCANCELAMENTO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('MODALRETIFICALAUDO.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            id: Number,
            guiaId: Number,
            selecionado: Array,
            validado: Boolean,
        },
        data() {
            return {
                procurarMotivosRetificacao: '',
                listaMotivosRetificacao: [],
                motivoRetificacaoSelecionado: [],
                erroCancelamentoExame: null,
            }
        },
        computed: {
            filteredMotivosCancelamentoExame() {
                return this.procurarMotivosRetificacao
                    ? this.listaMotivosRetificacao.filter(x=>x.descricaoInterno!=null)
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosRetificacao.toUpperCase()) > -1)
                    : this.listaMotivosRetificacao
            },
        },
        mounted() {
            this.loadListaMotivosRetificacao()
        },
        methods: {
            loadListaMotivosRetificacao() {
                this.$http.get('/api/search/MotivosRetificacao')
                    .then(m => {
                        this.listaMotivosRetificacao = m.data
                    })
            },
            confirmarRetificacao() {
                if(this.motivoRetificacaoSelecionado == null || this.motivoRetificacaoSelecionado.length == 0) {

                    return this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('TOAST.MOTIVOCANCELAMENTO'),
                        type: 'is-warning'
                    })
                }

                this.motivoRetificacaoSelecionado = [this.motivoRetificacaoSelecionado];
                
                this.$http.post('/api/analitico/retificarEstudo', {
                    id: this.id,
                    guiaId: this.guiaId,
                    item: this.selecionado.map(x => x.item),
                    motivoIds: this.motivoRetificacaoSelecionado,
                    validado: this.validado,
                })
                .then((res) => {
                    if (res.body.erro) {
                        this.erroCancelamentoExame = res.body.erro
                    } else {
                        this.$emit('close')
                        this.$emit('retificacaoConcluida')
                        this.$emit('salvar')
                    }
                })
            },
        }
    }
</script>