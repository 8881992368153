
<template>
    <div class="column is-12">
        <div v-for="(evento,index) in eventos" :key="index" :class="tipoEventoStyle(evento)">

            <div class="mt-2 column is-1 has-text-centered">
                <b class="is-size-7-mobile is-size-6 is-paddingless">{{evento.hour}}</b>
                <b-icon v-if="evento.tipoAgendamentoCor"
                        :style="tipoAgendamentoStyle(evento)"
                        size="is-small"
                        icon="square-rounded"></b-icon>
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && !isConfirmado(evento)" :title="$t('AGENDA.PACIENTENAOCONFIRMADO')" class="has-text-danger">!</b>
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && isConfirmado(evento)" :title="$t('AGENDA.PACIENTECONFIRMADO')" class="has-text-success">&radic;</b>
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && isEncaixe(evento)" :title="$t('AGENDA.PACIENTEENCAIXE')" class="has-text-black">&crarr;</b>

            </div>
            <div class="column is-flex is-justify-content-space-between mediaScreen" style="min-height: 40px;">
                <div class="column" style="flex-basis: 25%;">
                    <div class="size-5 is-size-7-mobile" :title="evento.pacienteNome">
                        <b>{{ tratarNomePaciente(evento) }}</b>
                    </div>
                    <div class="size-5 is-size-7-mobile" :title="evento.pacienteResponsavelNome">
                        <p v-if="evento.pacienteResponsavelNome">Resp: {{ tratarNome(evento.pacienteResponsavelNome) }}</p>
                    </div>
                </div>
                <div class="column" style="flex-basis: 5%; text-align:center;">
                    <div class="size-5 is-size-7-mobile">
                        <span>{{ tratarIdadePaciente(evento) }}</span>
                    </div>
                </div>
                <div class="column" style="flex-basis: 15%;">
                    <div class="size-5 is-size-7-mobile">
                        <span>{{ evento.pacienteCelular || evento.pacienteFone }}</span>
                    </div>
                    <div class="size-5 is-size-7-mobile">
                        <span>{{ evento.pacienteResponsavelCelular || evento.pacienteResponsavelFone }}</span>
                    </div>
                </div>
                <div class="column" style="flex-basis: 15%;">
                    <div class="size-5 is-size-7-mobile">
                        <span>{{ evento.pacienteCidade }}</span>
                    </div>
                    <div class="size-5 is-size-7-mobile">
                        <span>{{ evento.pacienteResponsavelCidade }}</span>
                    </div>
                </div>
                <div class="column" style="flex-basis: 15%;">
                    <p class="size-5 is-size-7-mobile margin-convenio">
                        {{ evento.convenioNome }}
                    </p>
                    <div class="size-5 is-size-7-mobile">
                        <span>{{ evento.executanteNome }}</span>
                    </div>
                </div>
                <div class="is-inline size-5 is-size-7-mobile mediaScreen-start">                 
                    <p class="is-flex is-flex-direction-column mr-2 exames-container fixed-width-exames">
                        <span v-if="evento.guiaAgendaExamesNome">{{ evento.guiaAgendaExamesNome.join(",") }}</span>
                    </p>
                </div>
            </div>
           
            <div class="mt-2" v-show="habilitarBotoes(evento)" style="min-height: 40px;">
                <b-button class="is-pulled-right  is-small is-responsive mr-2" position="is-bottom-left" v-show="!isBloqueio(evento) && !isAgendado(evento) && !isReservado(evento)" :title="$t('AGENDA.BLOQUEARHORARIO')"
                          icon-left="cancel" type="is-black" @click="bloquearHorario(evento)"></b-button>
                <b-button class="is-pulled-right  is-small is-responsive mr-2" position="is-bottom-left" v-show="isBloqueio(evento)  || isReservado(evento) "
                          :title="$t('AGENDA.DESBLOQUEARHORARIO')" icon-left="lock-open" @click="desbloquearHorario(evento.id) "></b-button>

                <b-checkbox v-if="(exibirCheckBox)" :native-value="true" v-model="evento.selecionado" :disabled="evento.bloquearAgenda"
                            class="is-pulled-right mx-0" v-show="!isBloqueio(evento) && !isAgendado(evento) && !isReservado(evento) " @input="verificarAgenda(evento)">
                </b-checkbox>
                <b-button v-else class=" is-pulled-right is-small is-responsive" position="is-bottom-left" v-show="!isBloqueio(evento) && !isAgendado(evento) && !isReservado(evento)" :title="$t('AGENDA.INCLUIRPACIENTE')"
                          icon-left="account-plus" @click="incluirNovoHorarioDia(evento, evento.dataHora)">
                </b-button>
            </div>
        </div>

    </div>
</template>

<style scoped>
    @media screen and (max-width: 769px) {
        .mediaScreen {
            flex-direction: column;
        }
    }

    @media screen and (min-width: 769px) {
        .mediaScreen-start {
            justify-content: flex-end;
        }
    }

    .mediaScreen-start {
        justify-content: flex-end;
    }

    .exames-container {
        max-width: 30rem;
        word-break: break-word;
        white-space: normal;
        text-align: left;
        margin-top: 8px;
    }   

    .fixed-width-exames {
        display: inline-block;
        width: 30ch;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 8px;
    }

    .margin-convenio{
        margin-bottom: 2px;
    }
   
    .no-radius {
        border-radius: 0 !important;
    }

    .border-itens {
        border: 1px solid #ffffff;
    }
</style>

<script>

import moment from 'moment';
import modalBloquearHorarios from '@/components/atendimento/modalBloquearHorarios.vue'
import { mapGetters } from 'vuex';

export default {
    props: {
        eventos: Array,        
        itemId: Number,            
        exibirCheckBox: Boolean,
        tiposAgendamentoAtivos: Array,
        usuario: Object             
    },
    computed: {
        ...mapGetters([
            'isInRole'
        ]),    
    },
    methods: {

            habilitarBotoes(evento) {
                return this.$moment(evento.dataHora).toDate() > new Date();
            },

            tipoAgendamentoStyle(evento) {
                return 'color: #' + evento.tipoAgendamentoCor + '!important'
            },
            tipoEventoStyle(evento) {
                let classes = 'box no-radius columns is-mobile is-gapless is-paddingless';

                if (this.isBloqueio(evento))
                    classes += ' border-itens has-text-light has-background-dark';

                if (this.isAgendado(evento))
                    classes += ' border-itens has-background-info has-text-light';

                if (this.isReservado(evento))
                    classes += ' border-itens has-background-dark has-text-danger-light';

                if (evento.descricaoExame)
                    classes += ' border-itens has-background-success';

                return classes;

            },

            tratarNomePaciente(evento){

                if (evento.pacienteNome) {
                    return evento.pacienteNome.toLowerCase();
                } else if (evento.observacao == 'Reservado' || evento.observacao == 'RESERVADO_TEMPORARIAMENTE') {
                    // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
                    return this.$t('AGENDA.HORARIORESERVADO');
                } else if (evento.id > 0 && !evento.pacienteNome && !evento.descricaoExame) {
                    return evento.motivoBloqueio != null ? `${this.$t('AGENDA.HORARIOBLOQUEADO')} (${evento.motivoBloqueio})` : this.$t('AGENDA.HORARIOBLOQUEADO');
                } else if (evento.descricaoExame) {
                    return evento.descricaoExame;
                }

            },
            tratarIdadePaciente(evento) {
                if (evento.pacienteDataNascimento) {
                    const dataNascimento = new Date(evento.pacienteDataNascimento);

                    if (isNaN(dataNascimento) || dataNascimento.getFullYear() < 1000) {
                        return '';
                    }

                    const dataAtual = new Date();

                    let idade = dataAtual.getFullYear() - dataNascimento.getFullYear();
                    const mes = dataAtual.getMonth() - dataNascimento.getMonth();

                    if (mes < 0 || (mes === 0 && dataAtual.getDate() < dataNascimento.getDate())) {
                        idade--;
                    }

                    return `${idade}a`;
                } else {
                    return '';
                }
            },
            tratarNome(nomeResp) {
                if (!nomeResp) {
                    return null;
                } else {
                    return nomeResp.toLowerCase();

                }                                           
            },

            isReservado(evento) {
                // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
                return (evento.id && !evento.pacienteNome && (evento.observacao == 'Reservado' || evento.observacao == 'RESERVADO_TEMPORARIAMENTE'));
            },
            isBloqueio(evento) {
                // TODO: O mais apropriado é tratar esse status de reservado no backend e retirar da observação
                return (evento.id && (!evento.pacienteNome || !evento.observacao == 'Reservado' || !evento.observacao == 'RESERVADO_TEMPORARIAMENTE') && evento.observacao == 'Bloqueio');
            },
            isAgendado(evento) {
                return (evento.pacienteNome);
            },
            isConfirmado(evento) {

                return (evento.pacienteConfirmado);
            },
            isEncaixe(evento) {

                return (evento.encaixe);
            },
            desbloquearHorario(id){                
                const evento = {id: id};

                this.$http.get(`/api/agenda/BuscaLogUsuarioGuiaAgenda?eventoId=${id}`)
                    .then(res => {
                        const eventoLog = res.data.log[0]
                        if(this.usuario.id == eventoLog.usuarioId){
                            this.agendaStatus(evento, 'cancelar');
                        } else{
                            if (this.isInRole('agenda-desbloquear-horario-outro-usuario')) {
                                this.agendaStatus(evento, 'cancelar');
                            } else {
                                this.$buefy.toast.open({
                                    message: this.$t('AGENDA.DESBLOQUEARHORARIOOUTROUSUARIO'),
                                    type: 'is-warning',
                                    duration: 5000
                                });
                            }
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            },           


        verificarAgenda(evento) {            
            this.$emit("verificarAgenda", evento);
            
        }, 
        bloquearHorario(evento){
            const dataHoraFim = this.$moment(evento.dataHora).add(evento.intervaloDuracaoAtendimento, 'm').toDate();
            const tipoAgendamento = this.tiposAgendamentoAtivos.find(t => t.id == evento.tipoAgendamentoId);   

            this.$buefy.modal.open({
                    parent: this,
                    component: modalBloquearHorarios,
                    events: {
                        loadDataDia: this.loadDataDia
                    },  
                    props: {
                        tipoAgendamento: tipoAgendamento,
                        dataHoraFim: dataHoraFim,
                        evento: evento,
                    },                                    
                    hasModalCard: true,
                    trapFocus: true
                });            
        },          
        loadDataDia(){
            this.$emit("loadDataDia");
        },
         incluirNovoHorarioDia(evento,dataHora, bloqueio){   
            if(evento.id > 0){

                this.$buefy.dialog.confirm({
                    title: this.$t('AGENDA.ATENCAO'),
                    message: this.$t('AGENDA.CONFIRMAPACIENTE'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {       
                        evento.funcionarioId = null                                                                   
                        this.$emit("incluirNovoHorarioDia", evento,dataHora,bloqueio);                        
                    },
                                    
                })

            }else{
                evento.itemId = this.itemId;   
                this.$emit("incluirNovoHorarioDia", evento,dataHora,bloqueio);
            }


            },
            async agendaStatus(evento, acao) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);

                params.push(`acao=${acao}`);
                if (evento.itemId) params.push(`itemId=${evento.itemId}`);

                try {
                    const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                    const res = await this.$http.get(url);
                    this.$emit('loadDataDia');
                } catch (e) {
                    console.error(e);
                }

            }
        }
    };
</script>