<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">Cancelar Fatura</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">Atenção !</h5>
                <p>Este procedimento irá cancelar está fatura.</p>
            </b-notification>
            <b-notification v-if="erroCancelamentoFatura"
                            type="is-danger">
                {{ erroCancelamentoFatura }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field label="Motivos">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosCancelamentoFatura"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosCancelamentoFatura = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="10"
                              expanded
                              v-model="motivoCancelamentoFaturaSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosCancelamentoFatura" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">            
            <b-button type="is-danger" :loading="salvandoDados"
                      @click="confirmarCancelamentoFatura">Confirmar cancelamento</b-button>
            <b-button @click="$emit('close')" :loading="salvandoDados">Cancelar</b-button>
            <b-button v-if="exibirContador && !contaPagar" type="is-success">Cancelando: {{countStatusRemovidos}} de {{totalParaRemover}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            model: Object,
            isLoading: Boolean,
            contaPagar: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                procurarMotivosCancelamentoFatura: '',
                listaMotivosCancelamentoFatura: [],
                motivoCancelamentoFaturaSelecionado: [],
                erroCancelamentoFatura: null,
                salvandoDados: false,
                exibirContador: false,
                countStatusRemovidos: 0,
                totalParaRemover: 0,
            }
        },
        computed: {
            filteredMotivosCancelamentoFatura() {
                return this.procurarMotivosCancelamentoFatura
                    ? this.listaMotivosCancelamentoFatura
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosCancelamentoFatura.toUpperCase()) > -1)
                    : this.listaMotivosCancelamentoFatura
            },
        },
        mounted() {
            this.loadListaMotivosCancelamentoFatura();
            if(!this.contaPagar){
                this.loadCountExamesReceber();
            }
        },
        methods: {
            loadListaMotivosCancelamentoFatura() {
                this.$http.get('/api/search/MotivosCancelamentoFatura')
                    .then(m => {
                        this.listaMotivosCancelamentoFatura = m.data
                    })
            },
            loadCountExamesReceber() {
                this.$http.get(`/api/financeiro/CountExamesReceber?receberId=${this.model.id}`)
                    .then(m => {
                        this.totalParaRemover = m.data
                    })
            },
            confirmarCancelamentoFatura() {
                this.salvandoDados = true;

                let url = '/api/financeiro/cancelaFaturamento';

                let params = {
                    receberId: this.model.id,
                    motivoIds: this.motivoCancelamentoFaturaSelecionado
                };
                if(this.motivoCancelamentoFaturaSelecionado.length == 0) {
                    this.salvandoDados = false;

                    return this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('TOAST.MOTIVOCANCELAMENTO'),
                        type: 'is-warning'
                    })
                }

                if(this.contaPagar){
                    url= '/api/financeiro/cancelaPagarFaturamento';
                    params = {
                        pagarId: this.model.id,
                        motivoIds: this.motivoCancelamentoFaturaSelecionado
                    };                    
                }

                this.$http.post(url,params )
                .then((res) => {
                    if (res.body.erro) {
                        this.erroCancelamentoFatura = res.body.erro
                    } 
                    else if(!this.contaPagar && res.data.examesIds.length > 0) {
                        this.exibirContador = true;
                        this.removerStatusFaturado(res.data.examesIds);
                    }
                    else {
                        this.$emit('close');
                        this.$router.push({ name: "financeiro" });
                        this.salvandoDados = false;
                    }
                });
            },
            async removerStatusFaturado(ids) {
                this.totalParaRemover = ids.length;

                for (const id of ids) {
                    const params = {
                        guiaExameId: id,
                        receberId: this.model.id
                    };

                    const { res } = await this.$http.post('/api/financeiro/removerStatus', params);

                    if (res?.body?.erro) {
                        this.erroCancelamentoFatura = res.body.erro;
                    }

                    this.countStatusRemovidos += 1;   
                }
                
                this.$emit('close');
                this.$router.push({ name: "financeiro" });
                this.salvandoDados = false;
                this.exibirContador = false;

            },
        }
    }
</script>