<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box has-text-centered">
                    <span>
                        Guias impressas no lote {{lote}}
                        <span v-if="model.length > 0"> na data {{ $moment(model[0].dataImpressao).format("DD/MM/YYYY HH:mm") }}</span>
                    </span>
                </article>
            </div>                  
        </div>

        <b-table :data="model" class="table-fix"
                 :loading="loading"
                 striped
                 hoverable>

            <template >
                <b-table-column  v-slot="props" field="guiaId" label="Guia">
                    <router-link :to="'/atendimento/guia/'+props.row.guiaId" class="is-primary is-small is-pulled-right">
                        {{ props.row.guiaId }}
                    </router-link>
                </b-table-column>

                <b-table-column  v-slot="props" field="Paciente" label="Paciente">
                    {{ props.row.paciente }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Convenio" label="Convênio">
                    {{ props.row.convenio }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Medico" label="Solicitante">
                    {{ props.row.medico }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Local De Origem" label="Local de Origem">
                    {{ props.row.localDeOrigem }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Exames" label="Exames">
                    <span v-for="(item, index) in props.row.exames" v-bind:key="index" class="tag is-default">
                        {{item}}<span> </span>
                    </span>
                </b-table-column>

                <b-table-column  v-slot="props" field="Data" label="Data de Emissão">
                    {{ $moment(props.row.dataEmissao).format("DD/MM/YYYY HH:mm")}}
                </b-table-column>

            </template>
        </b-table>
        <br>
        <nav class="level">
            <div class="level-item">
                <div class="buttons">
                    <b-button v-if="config.imprimeResultadosLoteViaBrowser" type="is-success"
                        :loading="loading"
                        @click="imprimirEmLoteViaBrowser"
                        icon-left="check-circle"
                    >
                        {{ $t('MENUANALISE.IMPRIMIRLOTE') }}
                    </b-button>
                    <form v-else action="/Home/ImpressaoPdf" method="post">
                        <input type="hidden" name="apenasNaoImpressos" value="false" />
                        <input type="hidden" name="descricao" value="Resultado" />
                        <input type="hidden" name="loteResultados" value="true" />
                        
                        <input v-for="(item, index) in model" v-bind:key="index" type="hidden" name="guiaExames" :value="item.guiaId" />

                        <b-button type="is-success"
                                :loading="loading"
                                native-type="submit"
                                icon-left="check-circle">
                            Imprimir
                        </b-button>
                    </form>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        Voltar
                    </b-button>
                </div>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'


    export default {
        data() {
            return {
                model: [],
                lote: null,
                loading: false
            };
        },
        components: {
            titlebar,            
        },
        created() {
            if (this.$route.params.id) {
                this.loading = true;
                this.lote = this.$route.params.id;
                this.$http.get('/api/analitico/abrirlote?lote=' + this.$route.params.id)
                    .then(res => {
                        this.loading = false;
                        this.model = res.data;                      
                    })

            }
        },
        computed: {
            titleStack() {
                return [
                    'Análise',
                    'Lotes de impressão',
                    this.$route.params.id
                ]
            },          
            ...mapState([
                'unidadeId',
                'config',
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            imprimirEmLoteViaBrowser() {
                alert(this.$t("MENUANALISE.IMPRIMIRLOTEALERTA"))
                let query = `guiaIds=${this.model.map(item => item.guiaId).join(',')}`;
                query += `&lote=${this.$route.params.id}`
                window.open('/api/Analitico/ImprimirEmLote?guiaIds=' + query);
            }
        }
    }
</script>